/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/extensions */
import {
  BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';
import {
  CATEGORIES_SCREEN_PATH,
  CHANGE_PASS_PATH,
  CLIENTS_SCREEN_PATH,
  COMPANIES_SCREEN_PATH,
  CREATE_CATEGORIES_SCREEN_PATH,
  CREATE_CLIENTS_SCREEN_PATH,
  CREATE_COMPANIES_SCREEN_PATH,
  CREATE_PRODUCTS_SCREEN_PATH,
  CREATE_SUB_CATEGORIES_SCREEN_PATH,
  CREATE_SUPPLIER_SCREEN_PATH,
  CREATE_TAXES_SCREEN_PATH,
  CREATE_USERS_SCREEN_PATH,
  DETAIL_ORDERS_SCREEN_PATH,
  EDITE_CATEGORIES_SCREEN_PATH,
  EDITE_CLIENTS_SCREEN_PATH,
  EDITE_COMPANIES_SCREEN_PATH,
  EDITE_PRODUCTS_SCREEN_PATH,
  EDIT_SUB_CATEGORIES_SCREEN_PATH,
  EDITE_SUPPLIER_SCREEN_PATH,
  EDITE_TAXES_SCREEN_PATH,
  EDITE_USERS_SCREEN_PATH,
  FOLLOWUP_SCREEN_PATH,
  HOME_PAGE_PATH,
  LOGIN_SCREEN_PATH,
  ORDERS_SCREEN_PATH,
  PRODUCTS_SCREEN_PATH,
  PROFILE_SCREEN_PATH,
  RESET_PASS_INFO_PATH,
  RESET_PASS_PATH,
  ROOT_PAGE_PATH,
  SUB_CATEGORIES_SCREEN_PATH,
  SUPPLIER_SCREEN_PATH,
  TAXES_SCREEN_PATH,
  USERS_SCREEN_PATH,
} from './paths.js';
import Login from '../screens/login/index.js';
import LayoutContainer from '../components/layout';
import ResetPassword from '../screens/resetPassword/index.js';
import InfoResetPassword from '../screens/resetPassword/info/index.js';
import FormResetPassword from '../screens/resetPassword/form/index.js';
import Home from '../screens/home/index.js';
import Users from '../screens/users/index.js';
import Orders from '../screens/order/index.js';
import Products from '../screens/product/index.js';
import Clients from '../screens/clients/index.js';
import Suppliers from '../screens/suppliers/index.js';
import Companies from '../screens/company/index.js';
import CreateEditCompanies from '../screens/company/createEdit/index.js';
import CreateEditUsers from '../screens/users/createEdit/index.js';
import EditProducts from '../screens/product/edit/index.js';
import CreateProducts from '../screens/product/create/index.js';
import CreateEditClients from '../screens/clients/createEdit/index.js';
import CreateEditSuppliers from '../screens/suppliers/createEdit/index.js';
import Categories from '../screens/categories/index.js';
import CreateEditCategories from '../screens/categories/createEdit/index.js';
import CreateEditTaxes from '../screens/taxes/createEdit/index.js';
import Taxes from '../screens/taxes/index.js';
import { rolKey } from '../utils/keys.js';
import { Roles } from '../utils/enums/roles.js';
import Profile from '../screens/profile/index.js';
import OrderDetails from '../screens/order/details/index.js';
import FollowUp from '../screens/followUp/index.js';
import TrackingMap from '../screens/followUp/map/index.js';
import SubCategories from '../screens/subCategories/index.js';
import CreateEditSubCategories from '../screens/subCategories/createEdit/index.js';

const rol = localStorage.getItem(rolKey);

const routes = [
  {
    path: LOGIN_SCREEN_PATH,
    element: <Login />,
    withLayout: false,
  },
  {
    path: RESET_PASS_PATH,
    element: <ResetPassword />,
    withLayout: false,
  },
  {
    path: RESET_PASS_INFO_PATH,
    element: <InfoResetPassword />,
    withLayout: false,
  },
  {
    path: `${CHANGE_PASS_PATH}`,
    element: <FormResetPassword />,
    withLayout: false,
  },
  {
    path: PROFILE_SCREEN_PATH,
    element: <Profile />,
    withLayout: true,
  },
  {
    path: HOME_PAGE_PATH,
    element: <Home />,
    withLayout: true,
  },
  {
    path: USERS_SCREEN_PATH,
    element: <Users />,
    withLayout: true,
  },
  {
    path: `${USERS_SCREEN_PATH}${CREATE_USERS_SCREEN_PATH}`,
    element: <CreateEditUsers isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${USERS_SCREEN_PATH}${EDITE_USERS_SCREEN_PATH}/:id`,
    element: <CreateEditUsers />,
    withLayout: true,
  },
  {
    path: ORDERS_SCREEN_PATH,
    element: <Orders />,
    withLayout: true,
  },
  {
    path: `${ORDERS_SCREEN_PATH}${DETAIL_ORDERS_SCREEN_PATH}/:id`,
    element: <OrderDetails />,
    withLayout: true,
  },
  {
    path: PRODUCTS_SCREEN_PATH,
    element: <Products />,
    withLayout: true,
  },
  {
    path: `${PRODUCTS_SCREEN_PATH}${CREATE_PRODUCTS_SCREEN_PATH}`,
    element: <CreateProducts isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${PRODUCTS_SCREEN_PATH}${EDITE_PRODUCTS_SCREEN_PATH}/:id`,
    element: <EditProducts />,
    withLayout: true,
  },
  {
    path: CATEGORIES_SCREEN_PATH,
    element: <Categories />,
    withLayout: true,
  },
  {
    path: `${CATEGORIES_SCREEN_PATH}${CREATE_CATEGORIES_SCREEN_PATH}`,
    element: <CreateEditCategories isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${CATEGORIES_SCREEN_PATH}${EDITE_CATEGORIES_SCREEN_PATH}/:id`,
    element: <CreateEditCategories />,
    withLayout: true,
  },
  {
    path: SUB_CATEGORIES_SCREEN_PATH,
    element: <SubCategories />,
    withLayout: true,
  },
  {
    path: `${SUB_CATEGORIES_SCREEN_PATH}${CREATE_SUB_CATEGORIES_SCREEN_PATH}`,
    element: <CreateEditSubCategories isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${SUB_CATEGORIES_SCREEN_PATH}${EDIT_SUB_CATEGORIES_SCREEN_PATH}/:id`,
    element: <CreateEditSubCategories />,
    withLayout: true,
  },
  {
    path: CLIENTS_SCREEN_PATH,
    element: <Clients />,
    withLayout: true,
  },
  {
    path: `${CLIENTS_SCREEN_PATH}${CREATE_CLIENTS_SCREEN_PATH}`,
    element: <CreateEditClients isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${CLIENTS_SCREEN_PATH}${EDITE_CLIENTS_SCREEN_PATH}/:id`,
    element: <CreateEditClients />,
    withLayout: true,
  },
  {
    path: SUPPLIER_SCREEN_PATH,
    element: <Suppliers />,
    withLayout: true,
  },
  {
    path: `${SUPPLIER_SCREEN_PATH}${CREATE_SUPPLIER_SCREEN_PATH}`,
    element: <CreateEditSuppliers isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${SUPPLIER_SCREEN_PATH}${EDITE_SUPPLIER_SCREEN_PATH}/:id`,
    element: <CreateEditSuppliers />,
    withLayout: true,
  },
  {
    path: COMPANIES_SCREEN_PATH,
    element: rol === Roles.SUPERADMIN ? <Companies /> : <Navigate to="/" />,
    withLayout: true,
  },
  {
    path: `${COMPANIES_SCREEN_PATH}${CREATE_COMPANIES_SCREEN_PATH}`,
    element: rol === Roles.SUPERADMIN ? <CreateEditCompanies isEdit={false} /> : <Navigate to="/" />,
    withLayout: true,
  },
  {
    path: `${COMPANIES_SCREEN_PATH}${EDITE_COMPANIES_SCREEN_PATH}/:id`,
    element: rol === Roles.SUPERADMIN ? <CreateEditCompanies /> : <Navigate to="/" />,
    withLayout: true,
  },
  {
    path: TAXES_SCREEN_PATH,
    element: <Taxes />,
    withLayout: true,
  },
  {
    path: `${TAXES_SCREEN_PATH}${CREATE_TAXES_SCREEN_PATH}`,
    element: <CreateEditTaxes isEdit={false} />,
    withLayout: true,
  },
  {
    path: `${TAXES_SCREEN_PATH}${EDITE_TAXES_SCREEN_PATH}/:id`,
    element: <CreateEditTaxes />,
    withLayout: true,
  },
  {
    path: FOLLOWUP_SCREEN_PATH,
    element: <FollowUp />,
    withLayout: true,
  },
  {
    path: `${FOLLOWUP_SCREEN_PATH}/:id`,
    element: <TrackingMap />,
    withLayout: true,
  },
];

const getRoutes = () => routes.map(({ path, element, withLayout }) => (
  <Route
    path={path}
    element={withLayout ? <LayoutContainer>{element}</LayoutContainer> : element}
    key={path}
  />
));

const RouteApp = () => (
  <BrowserRouter>
    <Routes>
      {getRoutes()}
      <Route
        path={ROOT_PAGE_PATH}
        element={(
          <LayoutContainer>
            <Navigate to={rol ? HOME_PAGE_PATH : LOGIN_SCREEN_PATH} />
          </LayoutContainer>
      )}
      />
    </Routes>
  </BrowserRouter>
);

export default RouteApp;
