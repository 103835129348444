/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeaderSuppliers from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsSupplier } from '../../../utils/parsers/tableParser';
import { CREATE_SUPPLIER_SCREEN_PATH, EDITE_SUPPLIER_SCREEN_PATH, SUPPLIER_SCREEN_PATH } from '../../../routes/paths';
import { deleteSuppliers, fetchAllSuppliers, fetchCompanies } from '../../../utils/calls';
import { parseSuppliersList } from '../../../utils/parsers/parserSupplier';
import HeaderSearch from '../../../components/header-search';
import { searchSuppliers, searchSuppliersCompany } from '../../../utils/searchs';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import { withAuth } from '../../../hoc';

const BodySuppliers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteOneSupplier, setDeleteOneSupplier] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [companies, setCompanies] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [search, setSearch] = useState(null);
  const pageSize = 10;
  const rol = localStorage.getItem(rolKey);

  const getSuppliers = () => {
    setLoading(true);
    fetchAllSuppliers(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getSuppliers();
    if (rol === Roles.SUPERADMIN) {
      getCompanies();
    }
  }, []);

  const handleDelete = async (key) => {
    try {
      const deleteSupplierData = await deleteSuppliers(key);
      if (deleteSupplierData.status === 200) {
        setDeleteOneSupplier(true);
        toast.success(t('toastSuccess.deleteSupplier'));
      }
    } catch (error) {
      if (error.response && error.response.data.message === 'Error when deleting the item has associated.') {
        toast.error(t('toastError.errorAsociatedSupplierDelete'));
      } else {
        toast.error(t('toastError.deleteSupplier'));
      }
    }
  };

  useEffect(() => {
    if (deleteOneSupplier) {
      getSuppliers();
      setDeleteOneSupplier(false);
    }
  }, [deleteOneSupplier]);

  const redirectCreate = () => {
    navigate(`${SUPPLIER_SCREEN_PATH}${CREATE_SUPPLIER_SCREEN_PATH}`);
  };

  const redirect = (supplier) => {
    navigate(`${SUPPLIER_SCREEN_PATH}${EDITE_SUPPLIER_SCREEN_PATH}/${supplier.key}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleActionsColumn = (supplier) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(supplier);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteSupplier')}
            onConfirm={() => handleDelete(supplier.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );
  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (companies) {
      const inputSearch = searchSuppliersCompany(companies);
      setInputs(inputSearch);
    } else {
      setInputs(searchSuppliers);
    }
  }, [companies]);

  useEffect(() => {
    if (search) { getSuppliers(); }
  }, [currentPage, search]);

  useEffect(() => {
    getSuppliers();
  }, [currentPage]);
  return (
    <>
      <HeaderSuppliers />
      <div className="body-container__button">
        <Button
          text={t('button.createSupplier')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />

      <div className="body-container__table">
        <Table
          columns={columnsSupplier}
          loading={loading}
          dataSource={parseSuppliersList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodySuppliers);
