/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

const ApexChart = ({ seriesData, selectedPeriodGraph }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  let parseDate;
  if (selectedPeriodGraph === 'annual') {
    parseDate = 'Meses';
  } else if (selectedPeriodGraph === 'monthly') {
    parseDate = 'Dias';
  } else {
    parseDate = 'Horas';
  }

  const adjustDates = (dates, period) => {
    if (period === 'daily') {
      return dates.map((date) => moment(date, 'HH:mm').subtract(3, 'hours').format('HH:mm'));
    }
    return dates;
  };

  const chartData = {
    series: seriesData,
    options: {
      chart: {
        height: 350,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: !isMobile,
      },
      stroke: {
        curve: 'smooth'
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      markers: {
        size: 0
      },
      xaxis: {
        categories: adjustDates(seriesData.dates, selectedPeriodGraph),
        title: {
          text: parseDate
        }
      },
      yaxis: {
        title: {
          text: 'Ventas'
        },
        min: _.min(seriesData.orderCounts),
        max: _.max(seriesData.orderCounts) === 0 ? 20 : _.max(seriesData.orderCounts)
      }
    },
  };

  return (
    <div className="apex-chart-mobile" id="chart">
      <ReactApexChart options={chartData.options} series={[{ data: seriesData.orderCounts }]} type="line" height={350} />
    </div>
  );
};

ApexChart.propTypes = {
  seriesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ).isRequired,
};
export default ApexChart;
