/* eslint-disable no-undef */
/* eslint-disable max-len */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select, Spin
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import Button from '../../../components/button';
import Title from '../../../components/title';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';

const FormTaxes = ({
  isEdit, onEdit, onCreate, data, onChange, oneTaxe, profile, companies, isLoadingCompanies, isLoadingCreateEditTax
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const roleAdminCompany = Roles.ADMINCOMPANY;
  const navigate = useNavigate();
  const rol = localStorage.getItem(rolKey);
  const isRolEqualRoleAdminCompany = rol === roleAdminCompany;

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (rol === roleAdminCompany) {
      setIsFormComplete(
        data.name
        && data.porcentage
      );
    } else {
      setIsFormComplete(
        data.name
      && data.porcentage
      && data.company
      );
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneTaxe,
      company: oneTaxe?.company?.name
    });
  }, [oneTaxe]);

  const redirect = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeTaxe') : t('title.createTaxe')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="name"
                label={t('label.companyName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.name')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={isEdit ? oneTaxe?.name : ''}
                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="porcentage"
                label={t('label.porcentageName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.porcentage')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('porcentage', e.target.value)}
                  value={isEdit ? oneTaxe.porcentage : ''}
                  type="number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {!isRolEqualRoleAdminCompany
            && (
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="company"
                label={t('label.company')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  className="form-info-container__input"
                  placeholder={rol === roleAdminCompany ? profile?.company?.name : t('label.selectCompany')}
                  name="company"
                  onChange={(value) => handleChange('company', value)}
                  disabled={rol === roleAdminCompany}
                  notFoundContent={isLoadingCompanies ? <Spin /> : null}
                >
                  {companies?.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="container-form__button">
                <div className="form-info-container__button-edit">
                  <Button
                    className="form-info-container__submit-button"
                    text={t(`button.${isEdit ? 'editeTaxe' : 'createTaxe'}`)}
                    color="blue"
                    disabled={!isFormComplete}
                    onClick={onFinish}
                    loading={isLoadingCreateEditTax}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormTaxes.propTypes = {
  isEdit: PropTypes.bool,
  isLoadingCompanies: PropTypes.bool,
  isLoadingCreateEditTax: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  oneTaxe: PropTypes.shape(),
  profile: PropTypes.shape(),
  companies: PropTypes.arrayOf(PropTypes.shape())
};

FormTaxes.defaultProps = {
  isEdit: false,
  isLoadingCompanies: false,
  isLoadingCreateEditTax: false,
  data: {},
  oneTaxe: {},
  profile: {},
  companies: [],
  onChange: () => {},
  onCreate: () => {},
  onEdit: () => {},
};

export default FormTaxes;
