/* eslint-disable no-undef */
import {
  LeftOutlined,
} from '@ant-design/icons';
import {
  Col,
  Form,
  Row,
  Select,
  Typography,
  Upload,
  message
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { createProduct, fetchCompanies } from '../../../utils/calls';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import { PRODUCTS_SCREEN_PATH } from '../../../routes/paths';
import Button from '../../../components/button';
import { withAuth } from '../../../hoc';

const { Item } = Form;
const { Title } = Typography;
const CreateProducts = () => {
  const rol = localStorage.getItem(rolKey);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { user } = useSelector((state) => state.appReducer);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [parseForm, setParseForm] = useState({
    company: null,
    file: null
  });
  const [messageHolder] = message.useMessage();
  const { t } = useTranslation();

  const redirect = () => {
    navigate(PRODUCTS_SCREEN_PATH);
  };

  const onSubmit = async () => {
    if (!parseForm.file || !parseForm.company) {
      messageHolder.open({
        type: 'error',
        className: 'message',
        content: 'Please select a company and upload an Excel file.',
        duration: 5,
      });
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', parseForm.file[0].originFileObj);
    formData.append('company', parseForm.company);
    try {
      createProduct(formData)
        .then(() => {
          messageHolder.open({
            className: 'message',
            type: 'success',
            content: 'The file was successfully uploaded.',
            duration: 5,
          });
          redirect();
          toast.success(t('toastSuccess.createProduct'));
        })
        .catch((error) => {
          messageHolder.open({
            type: 'error',
            className: 'message',
            content: error.response.data.message,
            duration: 5,
          });
          if (error?.response?.data?.message === 'There is already a product with that sku'
          ) {
            toast.error(t('toastError.equealSku'));
          } else {
            toast.error(t('toastError.fetchingError'));
          }
        }).finally(
          setLoading(false)
        );
    } catch (error) {
      messageHolder.open({
        className: 'message',
        type: 'error',
        content: 'Error. The file upload could not be completed.',
        duration: 5,
      });
      setLoading(false);
    }
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setTimeout(() => {
      setFileList([]);
    }, 100);
    setFileList([]);
  };

  const handleChange = (field, value) => {
    setParseForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const handleUpload = (info) => {
    const updatedFileList = [info.file];
    setFileList(updatedFileList);
    handleChange('file', updatedFileList);
  };

  const beforeUpload = (file) => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      messageHolder.open({
        className: 'message',
        type: 'error',
        content: 'Error. Only Excel files (.xlsx) can be uploaded!',
        duration: 5,
      });
      setTimeout(() => {
        onRemove(file);
      }, 1000);
    }
    return isExcel;
  };

  const uploadButton = (
    <Button text={t('title.uploadExel')} />
  );

  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    if (user.company) {
      setParseForm((prevForm) => ({
        ...prevForm,
        company: user.company.id,
      }));
      form.setFieldsValue({
        company: user.company.id,
      });
    }
  }, [user]);

  return (
    <div>
      <div className="container-header">
        <Title>
          {t('title.createProduct')}
        </Title>
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form} layout="vertical">
          <Row gutter={16} align="bottom">
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="company"
                label={t('label.company')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  className="form-info-container__input"
                  placeholder={rol === Roles.ADMINCOMPANY ? user?.company?.name : t('label.selectCompany')}
                  name="company"
                  onChange={(value) => handleChange('company', value)}
                  disabled={rol === Roles.ADMINCOMPANY}
                >
                  {companies?.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="file"
                rules={[
                  {
                    required: rol === Roles.SUPERADMIN,
                    message: 'Select a file. It is required.',
                  },
                  {
                    type: 'file',
                    message: 'Select a valid file.',
                  }
                ]}
              >
                <Upload
                  beforeUpload={beforeUpload}
                  onRemove={onRemove}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                  accept=".xlsx"
                  fileList={fileList}
                  onChange={handleUpload}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </Item>
            </Col>
          </Row>
          <Item>
            <Button
              disabled={fileList?.length < 1}
              color="login"
              loading={loading}
              text={t('button.createProduct')}
              onClick={onSubmit}
            />
          </Item>
        </Form>
      </div>
    </div>
  );
};

export default withAuth(CreateProducts);
