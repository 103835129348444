/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import { ToastContainer } from 'react-toastify';
import './App.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import RouteApp from './routes/routes';
import { accessTokenKey } from './utils/keys';
import { setUser } from './redux/actions/app';
import { setAuthentication } from './services/api';
import { fetchProfile } from './utils/calls';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem(accessTokenKey);
    if (accessToken) {
      dispatch(setUser(fetchProfile));
      setAuthentication(accessToken);
    }
  }, []);

  return (
    <>
      <RouteApp />
      <ToastContainer />
    </>
  );
}

export default App;
