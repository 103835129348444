import { RESET_SELECTED_KEY, SET_SELECTED_KEY } from '../actions/types';

const initialState = {
  selectedKey: null,
};

const userKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_KEY:
      return {
        ...state,
        selectedKey: action.payload,
      };
    case RESET_SELECTED_KEY:
      return {
        ...state,
        selectedKey: null,
      };
    default:
      return state;
  }
};

export default userKeysReducer;
