/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createUsers, editeteUsers, fetchCompanies, fetchOneUser, fetchProfile, fetchRoles
} from '../../../utils/calls';
import { USERS_SCREEN_PATH } from '../../../routes/paths';
import FormUsers from '../form';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import { translateRoleName } from '../../../utils/parsers/parserRoles';
import { withAuth } from '../../../hoc';

const CreateEditUsers = ({ isEdit }) => {
  const rol = localStorage.getItem(rolKey);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.appReducer);
  const [oneUser, setOneUser] = useState();
  const [roles, setRoles] = useState();
  const [companies, setCompanies] = useState();
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isLoadingCreateEditUser, setIsLoadingCreateEditUser] = useState(false);
  const [profile, setProfile] = useState();
  const [form, setForm] = useState({
    name: null,
    lastName: null,
    phone: null,
    address: null,
    email: null,
    company: null,
    role: null,
    password: null
  });
  const isRoleAdminCompany = rol === Roles.ADMINCOMPANY;
  const isRoleSuperAdmin = rol === Roles.SUPERADMIN;

  const getRoles = (setLoading = () => { }) => {
    setLoading(true);
    fetchRoles()
      .then((response) => {
        const translatedRoles = response.data.map((role) => ({
          ...role,
          name: translateRoleName(role.name),
        }));

        setRoles(translatedRoles);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  const getProfile = () => {
    fetchProfile()
      .then((response) => {
        setProfile(response?.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getCompanies = (setLoading = () => { }) => {
    setLoading(true);
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getRoles(setIsLoadingRoles);
    if (isRoleSuperAdmin) {
      getCompanies(setIsLoadingCompanies);
    }
    if (isRoleAdminCompany) {
      getProfile();
    }
  }, []);

  const redirect = () => {
    navigate(USERS_SCREEN_PATH);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const handleCreate = () => {
    let formParser;

    if(isRoleAdminCompany){
      formParser={
        ...form,
        role:Roles.EMPLOYEE,
        company: user.company.id,
      }
    }

    setIsLoadingCreateEditUser(true)
    createUsers(isRoleAdminCompany ? formParser: form)
      .then(() => {
        toast.success(t('toastSuccess.createUser'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createUser'));
        setIsLoadingCreateEditUser(false);
      });
  };

  const handleEdit = () => {
    setIsLoadingCreateEditUser(true);
    editeteUsers(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editUser'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editUser'));
        setIsLoadingCreateEditUser(false);
      });
  };

  const getOneUser = () => {
    if (isEdit) {
      fetchOneUser(id)
        .then((response) => {
          setOneUser(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getOneUser();
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneUser ? oneUser?.name : '',
        phone: oneUser ? oneUser?.phone : '',
        address: oneUser ? oneUser?.address : '',
        email: oneUser ? oneUser?.email : '',
        lastName: oneUser ? oneUser?.lastName : '',
        role: oneUser ? oneUser?.role : '',
        company: oneUser ? oneUser?.company?.id : '',
        companyEmployee: oneUser ? oneUser?.companyEmployee?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneUser]);

  return (
    <FormUsers
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onCreate={handleCreate}
      onEdit={handleEdit}
      oneUser={oneUser}
      roles={roles}
      companies={companies}
      profile={profile}
      isLoadingCompanies={isLoadingCompanies}
      isLoadingRoles={isLoadingRoles}
      isLoadingCreateEditUser={isLoadingCreateEditUser}
    />
  );
};

CreateEditUsers.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditUsers.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditUsers);
