/*eslint-disable*/
export const parseOrdersDetail = ({
  orderLine,
  orderNumber
}) => ({
  key: orderLine.id,
  producto: orderLine.product?.name,
  precio: orderLine.product?.price,
  tipoDeProducto: orderLine.product?.productType?.name,
  rubro: orderLine.product?.category?.name,
  stock: orderLine.product?.stock,
  subTotal: orderLine.subTotal,
  cantidad: orderLine.count,
  nºDePedido: orderNumber ? orderNumber.toString().padStart(6, '0') : 0
});

export const parseOrdersDetailList = (data) => {
  return data.orderLines?.map(orderLine => parseOrdersDetail({ orderLine, orderNumber: data.orderNumber }));
};
