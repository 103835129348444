import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeaderClients from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsClient } from '../../../utils/parsers/tableParser';
import { CLIENTS_SCREEN_PATH, CREATE_CLIENTS_SCREEN_PATH, EDITE_CLIENTS_SCREEN_PATH } from '../../../routes/paths';
import { deleteClients, fetchAllClients } from '../../../utils/calls';
import { parseClientsList } from '../../../utils/parsers/parserClients';
import { searchCustommers } from '../../../utils/searchs';
import HeaderSearch from '../../../components/header-search';
import { withAuth } from '../../../hoc';

const BodyClients = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteneOneClient, setDeleteOneClient] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const pageSize = 10;

  const getClients = () => {
    setLoading(true);
    fetchAllClients(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClients();
  }, [currentPage]);

  const handleDelete = async (key) => {
    try {
      const deleteClientsData = await deleteClients(key);
      if (deleteClientsData.status === 200) {
        setDeleteOneClient(true);
        toast.success(t('toastSuccess.deleteClient'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteClient'));
    }
  };

  useEffect(() => {
    if (deleteneOneClient) {
      getClients();
      setDeleteOneClient(false);
    }
  }, [deleteneOneClient]);

  const redirectCreate = () => {
    navigate(`${CLIENTS_SCREEN_PATH}${CREATE_CLIENTS_SCREEN_PATH}`);
  };

  const redirect = (client) => {
    navigate(`${CLIENTS_SCREEN_PATH}${EDITE_CLIENTS_SCREEN_PATH}/${client.key}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleActionsColumn = (client) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(client);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteClient')}
            onConfirm={() => handleDelete(client.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (search) { getClients(); }
  }, [currentPage, search]);
  return (
    <>
      <HeaderClients />
      <div className="body-container__button">
        <Button
          text={t('button.createClient')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={searchCustommers()} onSearch={handlePageSearch} />

      <div className="body-container__table">
        <Table
          columns={columnsClient}
          loading={loading}
          dataSource={parseClientsList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyClients);
