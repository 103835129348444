/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import FormCategories from '../form';
import { SUB_CATEGORIES_SCREEN_PATH } from '../../../routes/paths';
import {
  createSubCategories, editeSubCategories, fetchCategories, fetchOneSubCategory
} from '../../../utils/calls';
import Title from '../../../components/title';
import Button from '../../../components/button';
import { withAuth } from '../../../hoc';

const CreateEditSubCategories = ({ isEdit }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [categories, setCategories] = useState();
  const [oneSubCategory, setOneSubCategory] = useState();
  const { user } = useSelector((state) => state.appReducer);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingCreateEditCategory, setIsLoadingCreateEditCategory] = useState(false);
  const [form, setForm] = useState({
    name: null,
    description: null,
    category: null
  });

  const redirect = () => {
    navigate(SUB_CATEGORIES_SCREEN_PATH);
  };
  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const getCategories = (setLoading = () => { }) => {
    setLoading(true);
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = () => {
    setIsLoadingCreateEditCategory(true);
    createSubCategories(form)
      .then(() => {
        toast.success(t('toastSuccess.createSubCategory'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createSubCategory'));
        setIsLoadingCreateEditCategory(false);
      });
  };

  const handleEdit = () => {
    setIsLoadingCreateEditCategory(true);
    editeSubCategories(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editSubCategory'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editSubCategory'));
        setIsLoadingCreateEditCategory(false);
      });
  };

  const getOneSubCategory = () => {
    if (isEdit) {
      fetchOneSubCategory(id)
        .then((response) => {
          setOneSubCategory(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getCategories(setIsLoadingCategories);
    if (isEdit) {
      getOneSubCategory();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneSubCategory ? oneSubCategory?.name : '',
        description: oneSubCategory ? oneSubCategory?.description : '',
        category: oneSubCategory ? oneSubCategory?.category?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneSubCategory]);

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editSubCategory') : t('title.createSubCategory')} />
      </div>
      <div className="button-back">
        <Button
          onClick={redirect}
          color="back"
          text={<LeftOutlined />}
        />
      </div>
      <FormCategories
        data={form}
        isEdit={isEdit}
        onChange={handleChange}
        onCreate={handleCreate}
        categories={categories}
        onEdit={handleEdit}
        oneSubCategory={oneSubCategory}
        profile={user}
        isLoadingCategories={isLoadingCategories}
        isLoadingCreateEditCategory={isLoadingCreateEditCategory}
      />
    </>
  );
};

CreateEditSubCategories.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditSubCategories.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditSubCategories);
