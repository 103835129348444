import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeaderUser from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsUsers } from '../../../utils/parsers/tableParser';
import { CREATE_USERS_SCREEN_PATH, EDITE_USERS_SCREEN_PATH, USERS_SCREEN_PATH } from '../../../routes/paths';
import { deleteUser, fetchAllUsers, fetchRoles } from '../../../utils/calls';
import { parseUsersList } from '../../../utils/parsers/parserUsers';
import { translateRoleName } from '../../../utils/parsers/parserRoles';
import { searchUsers } from '../../../utils/searchs';
import HeaderSearch from '../../../components/header-search';
import { withAuth } from '../../../hoc';

const BodyUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteneOneCompany, setDeleteOneCompany] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [roles, setRoles] = useState();
  const [search, setSearch] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [data, setData] = useState([]);
  const pageSize = 10;

  const getUsers = () => {
    setLoading(true);
    fetchAllUsers(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getRoles = () => {
    fetchRoles()
      .then((response) => {
        const translatedRoles = response.data.map((role) => ({
          ...role,
          name: translateRoleName(role.name),
        }));

        setRoles(translatedRoles);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (search) {
      getUsers();
    }
  }, [currentPage, search]);

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  useEffect(() => {
    if (roles) {
      const inputSearchs = searchUsers(roles);
      setInputs(inputSearchs);
    }
  }, [roles]);

  const redirectCreate = () => {
    navigate(`${USERS_SCREEN_PATH}${CREATE_USERS_SCREEN_PATH}`);
  };

  const handleDelete = async (key) => {
    try {
      const deleteCompanyData = await deleteUser(key);
      if (deleteCompanyData.status === 200) {
        setDeleteOneCompany(true);
        toast.success(t('toastSuccess.deleteUser'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteUser'));
    }
  };

  useEffect(() => {
    if (deleteneOneCompany) {
      getUsers();
      setDeleteOneCompany(false);
    }
  }, [deleteneOneCompany]);

  const redirect = (user) => {
    navigate(`${USERS_SCREEN_PATH}${EDITE_USERS_SCREEN_PATH}/${user.key}`);
  };
  const handleActionsColumn = (user) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(user);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteUser')}
            onConfirm={() => handleDelete(user.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );
  return (
    <>
      <HeaderUser />
      <div className="body-container__button">
        <Button
          text={t('button.createUser')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />
      <div className="body-container__table">
        <Table
          columns={columnsUsers}
          loading={loading}
          dataSource={parseUsersList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyUsers);
