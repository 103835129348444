/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Navigate } from 'react-router-dom';
import { LOGIN_SCREEN_PATH } from '../routes/paths';

export const withAuth = (Component) => function AuthRoute(props) {
  const isAuthenticated = localStorage.getItem('accessToken');
  return isAuthenticated
    ? <Component {...props} />
    : <Navigate to={LOGIN_SCREEN_PATH} />;
};
