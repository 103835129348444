export const parseCompanies = ({
  id,
  name,
  address,
  phone
}) => ({
  key: id,
  nombre: name,
  direccion: address,
  celular: phone

});

export const parseCompaniesList = (list) => list?.map((camp) => parseCompanies(camp));
