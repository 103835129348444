export const Roles = {
  SUPERADMIN: 'superadmin',
  ADMINCOMPANY: 'admincompany',
  EMPLOYEE: 'employee',
};

export const HomeTime = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
};
