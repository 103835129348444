/*eslint-disable*/
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { formatDateTime } from '../../../../utils/parsers/dateparser';
import TableComponent from '../../../../components/table';
import { columnsOrdersDetail } from '../../../../utils/parsers/tableParser';
import { parseOrdersDetailList } from '../../../../utils/parsers/parserOrderDetail';
import { rolKey } from '../../../../utils/keys';
import { Roles } from '../../../../utils/enums/roles';

const FormDetailOrder = ({ data }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const rol = localStorage.getItem(rolKey);
  const isRoleSuperAdmin = rol === Roles.SUPERADMIN;
  
  return (
    <>
      <div className={`container-form__detail ${isMobile ? 'column-detail-mobile' : 'column-detail'}`}>
        <div className="column-detail">
          <h3 className="column-detail__title">{t('label.orderDetail')}</h3>
          <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.numberIdOrderDetail')}</p>
            <p className="column-detail__info-value">{data?.id}</p>
          </div>
          {isRoleSuperAdmin && <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.companyOrderDetail')}</p>
            <p className="column-detail__info-value">{data?.customer?.company?.name}</p>
          </div>}
          <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.dateOrderDetail')}</p>
            <p className="column-detail__info-value">{formatDateTime(data?.date)}</p>
          </div>
          <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.descriptionDetail')}</p>
            <p className="column-detail__info-value">{data?.description}</p>
          </div>
        </div>

        <div className="column-detail">
          <h3>{t('label.personalDetail')}</h3>
          <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.customerDetail')}</p>
            <p className="column-detail__info-value">{data?.customer?.name}</p>
          </div>
          <div className="column-detail__info">
            <p className="column-detail__info-label">{t('label.employeeDetail')}</p>
            <p className="column-detail__info-value">{data?.employee?.name}</p>
          </div>
        </div>
      </div>
      <div className="body-container__table">
        <div className={`container-form__detail ${isMobile ? 'column-detail-mobile' : 'column-detail'}`}>
          <div className="flex-end-container"> 
            <div className="column-detail__info column-detail__info__total">
              <p className="column-detail__info-label">{t('label.totalDetail')}</p>
              <p className="column-detail__info-value">{data?.total}</p>
            </div>
          </div>
        </div>
        <TableComponent
          columns={columnsOrdersDetail}
          dataSource={parseOrdersDetailList(data)}
          actions={false}
          scroll={{ x: 1200 }}
        />
      </div>
    </>
  );
};

FormDetailOrder.propTypes = {
  data: PropTypes.shape(),
};

FormDetailOrder.defaultProps = {
  data: {},
};

export default FormDetailOrder;
