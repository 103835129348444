export const translateRoleName = (roleName) => {
  switch (roleName) {
    case 'admincompany':
      return 'Encargado';
    case 'superadmin':
      return 'Administrador';
    case 'employee':
      return 'Empleado';
    default:
      return roleName;
  }
};
