/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createTaxes, editeTaxes, fetchCompanies, fetchOneTaxe, fetchProfile
} from '../../../utils/calls';
import FormTaxes from '../form';
import { TAXES_SCREEN_PATH } from '../../../routes/paths';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';
import { withAuth } from '../../../hoc';

const CreateEditTaxes = ({ isEdit }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneTaxe, setOneTaxe] = useState();
  const rol = localStorage.getItem(rolKey);
  const [profile, setProfile] = useState();
  const [companies, setCompanies] = useState();
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingCreateEditTax, setisLoadingCreateEditTax] = useState(false);
  const { user } = useSelector((state) => state.appReducer);
  const [form, setForm] = useState({
    name: '',
    porcentage: 0
  });
  const isRoleAdminCompany = rol === Roles.ADMINCOMPANY;

  const redirect = () => {
    navigate(TAXES_SCREEN_PATH);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const getProfile = () => {
    fetchProfile()
      .then((response) => {
        setProfile(response?.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };
  const handleCreate = () => {
    const formParser = {
      ...form,
      porcentage: Number(form.porcentage/100),
      company: isRoleAdminCompany ? profile?.company?.id : form.company
    };
    setisLoadingCreateEditTax(true);
    createTaxes(formParser)
      .then(() => {
        toast.success(t('toastSuccess.createTaxe'));
        redirect();
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || t('toastError.createTaxe');
        toast.error(errorMessage);
        setisLoadingCreateEditTax(false);
      });
  };

  const getCompanies = (setLoading = () => {}) => {
    setLoading(true);
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isEdit && user.role === Roles.SUPERADMIN) {
      getCompanies(setIsLoadingCompanies);
    }
    if (isRoleAdminCompany) {
      getProfile();
    }
  }, []);

  const handleEdit = () => {
    setisLoadingCreateEditTax(true);
    editeTaxes(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editTaxe'));
        redirect();
      })
      .catch((error) => {
        if (error.response && error.response.data.message === 'Error when editing the item has associated products.') {
          toast.error(t('toastError.errorAsociatedTaxesEdit'));
        } else {
          toast.error(t('toastError.editTaxe'));
        }
        setisLoadingCreateEditTax(false);
      });
  };

  const getOneTaxe = () => {
    if (isEdit) {
      fetchOneTaxe(id)
        .then((response) => {
          setOneTaxe(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getOneTaxe();
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneTaxe ? oneTaxe?.name : '',
        porcentage: oneTaxe ? oneTaxe?.porcentage : '',
        company: oneTaxe ? oneTaxe?.company?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneTaxe]);

  return (
    <FormTaxes
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onCreate={handleCreate}
      onEdit={handleEdit}
      oneTaxe={oneTaxe}
      profile={profile}
      companies={companies}
      isLoadingCompanies={isLoadingCompanies}
      isLoadingCreateEditTax={isLoadingCreateEditTax}
    />
  );
};

CreateEditTaxes.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditTaxes.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditTaxes);
