/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createSuppliers, editeSuppliers, fetchCompanies, fetchOneSupplier, fetchProfile
} from '../../../utils/calls';
import { SUPPLIER_SCREEN_PATH } from '../../../routes/paths';
import FormSuppliers from '../form';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';
import { withAuth } from '../../../hoc';

const CreateEditSuppliers = ({ isEdit }) => {
  const rol = localStorage.getItem(rolKey);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneSupplier, setOneSupplier] = useState();
  const [companies, setCompanies] = useState();
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingCreateEditSupplier, setisLoadingCreateEditSupplier] = useState(false);
  const [profile, setProfile] = useState();
  const [form, setForm] = useState({
    name: '',
    phone: '',
    address: '',
    company: ''
  });

  const redirect = () => {
    navigate(SUPPLIER_SCREEN_PATH);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const getCompanies = (setLoading = () => {}) => {
    setLoading(true);
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  const getProfile = () => {
    fetchProfile()
      .then((response) => {
        setProfile(response?.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    if (rol === Roles.SUPERADMIN) {
      getCompanies(setIsLoadingCompanies);
    }
    if (rol === Roles.ADMINCOMPANY) {
      getProfile();
    }
  }, []);

  const handleCreate = () => {
    const formParser = {
      ...form,
      company: profile?.company?.id
    };
    setisLoadingCreateEditSupplier(true);
    createSuppliers(rol === Roles.ADMINCOMPANY ? formParser : form)
      .then(() => {
        toast.success(t('toastSuccess.createSupplier'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createSupplier'));
        setisLoadingCreateEditSupplier(false);
      });
  };

  const handleEdit = () => {
    setisLoadingCreateEditSupplier(true);
    editeSuppliers(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editSupplier'));
        redirect();
      })
      .catch((error) => {
        if (error.response && error.response.data.message === 'Error when editing the item has associated products.') {
          toast.error(t('toastError.errorAsociatedSupplierEdit'));
        } else {
          toast.error(t('toastError.editSupplier'));
        }
        setisLoadingCreateEditSupplier(false);
      });
  };

  const getOneSupplier = () => {
    if (isEdit) {
      fetchOneSupplier(id)
        .then((response) => {
          setOneSupplier(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getOneSupplier();
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneSupplier ? oneSupplier?.name : '',
        phone: oneSupplier ? oneSupplier?.phone : '',
        address: oneSupplier ? oneSupplier?.address : '',
        company: oneSupplier ? oneSupplier?.company?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneSupplier]);

  return (
    <FormSuppliers
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onCreate={handleCreate}
      onEdit={handleEdit}
      oneSupplier={oneSupplier}
      companies={companies}
      profile={profile}
      isLoadingCompanies={isLoadingCompanies}
      isLoadingCreateEditSupplier={isLoadingCreateEditSupplier}
    />
  );
};

CreateEditSuppliers.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditSuppliers.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditSuppliers);
