import { getDate } from './dateparser';

export const parseHomeEmployee = ({
  id,
  name,
  count,
  lastOrder
}) => ({
  key: id,
  nombre: name,
  cantidadDeVenta: count,
  fechaUltimaVenta: getDate(lastOrder)
});
export const parseHomeEmployeeList = (list) => list?.map((camp) => parseHomeEmployee(camp));
