import { formatDateTime } from './dateparser';

export const parseOrders = ({
  id,
  name,
  description,
  createdAt,
  total,
  status,
  user,
  customer,
  employee,
  orderNumber
}) => ({
  key: id,
  nombre: name,
  descripcion: description,
  fecha: formatDateTime(createdAt),
  total,
  estado: status,
  usuario: user?.name,
  idPedido: id,
  nºDePedido: orderNumber ? orderNumber.toString().padStart(6, '0') : 0,
  empleado: `${employee.name} ${employee.lastName}`,
  cliente: `${customer.name} ${customer.lastName}`,
  empresa: `${customer.company.name}`

});

export const parseOrdersList = (list) => list?.map((camp) => parseOrders(camp));
