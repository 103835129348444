/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectSearch = ({
  text, onSelectChange, options, value
}) => (
  <div className="cointainer-select-search">
    <Select
      className="container-select-search"
      placeholder={`Seleccione ${text}`}
      value={(value === null || value === '') ? undefined : value}
      optionFilterProp="children"
      onChange={onSelectChange}
      allowClear
      filterOption={(input, option) => option.children.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0}
    >
      {options?.map((option) => (
        <Option key={option.id} value={option.id}>
          {option.lastName ? `${option.name} ${option.lastName}` : option.name}
        </Option>
      ))}
    </Select>
  </div>
);

export default SelectSearch;
