/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form, Typography } from 'antd';
import { login } from '../../redux/actions/app';
import { HOME_PAGE_PATH, RESET_PASS_PATH } from '../../routes/paths';
import FormLogin from './form';
import Logo from '../../assets/logos/vortex.svg';

const { Title, Link } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate(HOME_PAGE_PATH);
  };

  const handleSubmmit = (values) => {
    const loginForm = {
      ...values,
      email: values.email.toLowerCase()
    };
    dispatch(login(loginForm, handleSuccess));
  };
  return (
    <div className="login-container">
      <div className="login-container__logo">
        <img src={Logo} />
      </div>
      <div className="login-container__form">
        <Title>{t('login.login')}</Title>
        <FormLogin form={form} onSubmmit={handleSubmmit} />
        <div className="screen-container-login__footer">
          <Link href={RESET_PASS_PATH}>{t('login.forgotPassword')}</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
