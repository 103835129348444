import { Form } from 'antd';
import { validationEmailKey, validationPasswordKey } from './keys';
import ii18n from '../i18n';

export const getValidationRules = (key) => {
  if (key === validationEmailKey) {
    return [
      { required: true, message: ii18n.t('validation.enterEmail'), },
      { type: 'email', message: ii18n.t('validation.validEmail'), },
    ];
  }
  if (key === validationPasswordKey) {
    return [
      { required: true, message: ii18n.t('validation.enterPassword'), },
      { min: 8, max: 16, message: ii18n.t('validation.passwordLength'), },
      { pattern: /^(?=.*[A-Z])/, message: ii18n.t('validation.passwordUppercase'), },
      { pattern: /^(?=.*[a-z])/, message: ii18n.t('validation.passwordLowercase'), },
      { pattern: /^(?=.*\d)/, message: ii18n.t('validation.passwordNumber'), },
    ];
  }
  if (key === 'repeatPassword') {
    return [
      { required: true, message: ii18n.t('validation.confirmPassword') },
      {
        validator: (_, value) => {
          const newPasswordValue = Form.getFieldValue('newPassword');
          if (value && value !== newPasswordValue) {
            return Promise.reject(ii18n.t('validation.passwordNotMatch'));
          }
          return Promise.resolve();
        },
      },
    ];
  }
  if (key === 'highSchool') {
    return [
      { required: true, message: ii18n.t('validation.selectHighSchool'), },
    ];
  }
  if (key === 'university') {
    return [
      { required: true, message: ii18n.t('validation.selectUniversity'), },
    ];
  }
  return [
    { required: true, message: `${ii18n.t('validation.enter')} ${key}`, },

  ];
};

export const validateField = (key, value) => {
  if (value === '' || value === []) {
    if (key === 'password' || key === 'confirmPassword') {
      return {
        [key]: 'contraseña es requerida',
      };
    }
    if (key === 'tipografica') {
      return {
        [key]: 'tipo de gráfica es requerido',
      };
    }
    if (key === 'tipoReporte') {
      return {
        [key]: 'tipo de reporte es requerido',
      };
    }
    return {
      [key]: `${key} es requerido`,
    };
  }

  return {
    [key]: '',
  };
};

export const thereIsErrorInForm = (d) => {
  const arrayValues = Object.values(d);
  const thereIsError = arrayValues.some((item) => item);
  return thereIsError;
};
