/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select, Spin
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Title from '../../../components/title';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import InputPhone from '../../../components/input-phone';

const FormClients = ({
  isEdit, onEdit, onCreate, data, onChange,
  oneClient, companies, isLoadingCompanies, isLoadingCreateEditClient
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const rol = localStorage.getItem(rolKey);
  const roleAdminCompany = Roles.ADMINCOMPANY;
  const isRoleAdminCompany = rol === roleAdminCompany;

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };
 
  const redirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isRoleAdminCompany) {
      setIsFormComplete(
        data.name
        && data.lastName
        && data.phone
        && data.phone.length > 9
        && data.address
      );
    } else {
      setIsFormComplete(
        data.name
        && data.lastName
        && data.phone
        && data.phone.length > 9
        && data.address
        && data.company
      );
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneClient,
      company: oneClient?.company?.name
    });
  }, [oneClient]);

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeClient') : t('title.createClient')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="name"
                label={t('label.clientName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.name')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={isEdit ? oneClient.name : ''}
                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="lastName"
                label={t('label.clientLastName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.lastName')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('lastName', e.target.value)}
                  value={isEdit ? oneClient.lastName : ''}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="phone"
                label={t('label.clientPhone')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.phone')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <InputPhone
                  onChange={onChange}
                  data={data}
                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="address"
                label={t('label.clientAddress')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.address')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('address', e.target.value)}
                  value={isEdit ? oneClient.lastName : ''}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>

            {!isRoleAdminCompany && (
              <Col span={isMobile ? 16 : 10}>
                <Form.Item
                  name="company"
                  label={t('label.company')}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className="form-info-container__input"
                    placeholder={t('label.selectCompany')}
                    name="company"
                    onChange={(value) => handleChange('company', value)}
                    notFoundContent={isLoadingCompanies ? <Spin /> : null}
                  >
                    {companies?.map((company) => (
                      <Select.Option key={company.id} value={company.id}>
                        {company.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="container-form__button">
                <div className="form-info-container__button-edit">
                  <Button
                    text={t(`button.${isEdit ? 'editeClient' : 'createClient'}`)}
                    color="blue"
                    disabled={!isFormComplete}
                    onClick={onFinish}
                    loading={isLoadingCreateEditClient}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormClients.propTypes = {
  isEdit: PropTypes.bool,
  isLoadingCreateEditClient: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  oneClient: PropTypes.shape(),
  companies: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingCompanies: PropTypes.bool,
};

FormClients.defaultProps = {
  isEdit: false,
  isLoadingCreateEditClient: false,
  data: {},
  oneClient: {},
  companies: [],
  isLoadingCompanies: false,
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
};

export default FormClients;
