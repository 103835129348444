/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { Table, Tag, Tooltip } from 'antd';
import PropTypes, { bool } from 'prop-types';
import { useEffect, useState } from 'react';
import SpinnerInto from '../spinner-inner-components';

const TableComponent = ({
  columns, dataSource, isLoading, actionsColumn,
  idCopyFunc, idCopy = false,
  actions, titleCopy, dataCopy, ...restProps
}) => {
  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    const subcategoriesColumn = columns?.find((col) => col.key === 'subRubro');
    if (subcategoriesColumn) {
      subcategoriesColumn.render = (subcategories) => (
        <>
          {subcategories.map((category) => (
            <Tag style={{ color: 'black' }} color="blue" key={category}>
              {category}
            </Tag>
          ))}
        </>
      );
    }
  }, []);

  useEffect(() => {
    const newColumns = [...columns];
    const columnAction = columns?.find((col) => col.key === 'acción');
    const columnId = columns?.find((col) => col.key === 'key');
    const impuestosColumn = columns?.find((col) => col.key === 'impuestos');

    if (impuestosColumn) {
      impuestosColumn.render = (impuestos) => (
        <>
          {impuestos.map((impuesto) => (
            <Tag style={{ color: 'black' }} color="blue" key={impuesto}>
              {impuesto}
            </Tag>
          ))}
        </>
      );
    }
    if (idCopyFunc && idCopy && !columnId) {
      if (titleCopy !== 'Id') {
        newColumns.unshift({
          title: titleCopy,
          dataIndex: 'sensorId',
          key: 'sensorId',
          render: (text, record) => idCopyFunc(record),
        });
      } else {
        newColumns.unshift({
          title: 'Id',
          dataIndex: 'key',
          key: 'key',
          render: (text, record) => idCopyFunc(record),
        });
      }
    }

    if (!columnAction && actions) {
      newColumns?.push({
        title: 'Acción',
        dataIndex: 'acción',
        key: 'acción',
        render: (_, record) => actionsColumn(record),
      });
    }
    setColumnsList(newColumns);
  }, [columns, dataSource, dataCopy]);

  useEffect(() => {
    const newColumns = columns.map((column) => {
      const newColumn = { ...column };

      if (column.dataIndex) {
        newColumn.render = (text, record) => (
          <Tooltip title={text} placement="topLeft">
            {column.render ? column.render(text, record) : text}
          </Tooltip>
        );
      }

      return newColumn;
    });
    if (actions && !newColumns.some((col) => col.key === 'acción')) {
      newColumns.push({
        title: 'Acción',
        dataIndex: 'acción',
        key: 'acción',
        render: (_, record) => actionsColumn(record),
      });
    }

    setColumnsList(newColumns);
  }, [columns, dataSource, actionsColumn]);

  const NoDataMessage = () => (
    <div style={{ textAlign: 'center', padding: 20 }}>No hay datos disponibles</div>
  );

  return (
    <Table
      columns={columnsList}
      dataSource={dataSource}
      pagination={false}
      loading={{ spinning: isLoading, indicator: <SpinnerInto /> }}
      locale={{
        emptyText: <NoDataMessage />
      }}
      {...restProps}
    />
  );
};
TableComponent.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: bool,
  actionsColumn: PropTypes.func,
  idCopyFunc: PropTypes.func,
  idCopy: PropTypes.bool,
  actions: PropTypes.bool,
  titleCopy: PropTypes.string,
  dataCopy: PropTypes.string,
};
TableComponent.defaultProps = {
  actionsColumn: () => { },
  isLoading: false,
  idCopyFunc: () => { },
  dataSource: [],
  idCopy: false,
  actions: true,
  titleCopy: '',
  dataCopy: ''
};

export default TableComponent;
