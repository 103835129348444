/* eslint-disable*/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select, Spin
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/button';
import { SUB_CATEGORIES_SCREEN_PATH, CREATE_SUB_CATEGORIES_SCREEN_PATH, EDIT_SUB_CATEGORIES_SCREEN_PATH } from '../../../routes/paths';

const FormSubCategories = ({
  isEdit, onEdit, onCreate, data, onChange, categories, isLoadingCategories, isLoadingCreateEditSubCategory, oneSubCategory
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { pathname } = useLocation();
  const createSubCategoriesPath = `${SUB_CATEGORIES_SCREEN_PATH}${CREATE_SUB_CATEGORIES_SCREEN_PATH}`;
  const editeSubCategoriesPath = `${SUB_CATEGORIES_SCREEN_PATH}${EDIT_SUB_CATEGORIES_SCREEN_PATH}`;

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
    if (pathname === SUB_CATEGORIES_SCREEN_PATH) {
      onEdit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const formCompleteCondition = data.name && data.description;
    setIsFormComplete(formCompleteCondition);
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneSubCategory,
      category: oneSubCategory?.category?.id
    });
  }, [oneSubCategory, form]);

  return (
    <div className="container-form">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              name="name"
              label={t('label.productName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: pathname === createSubCategoriesPath,
                  message: `${t('label.name')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              name="description"
              label={t('label.descriptionName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: pathname === createSubCategoriesPath,
                  message: `${t('label.description')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          {
            (pathname === createSubCategoriesPath || pathname.includes(editeSubCategoriesPath)) && (
              <Col span={isMobile ? 16 : 10}>
                <Form.Item
                  name="category"
                  label={t('label.category')}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className="form-info-container__input"
                    placeholder={t('label.selecCategory')}
                    name="category"
                    onChange={(value) => handleChange('category', value)}
                    notFoundContent={isLoadingCategories ? <Spin /> : null}
                  >
                    {categories?.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )
          }
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <div className="container-form__button">
              <div className="form-info-container__button-edit">
                <Button
                  className="form-info-container__submit-button"
                  text={t(`button.${isEdit ? 'editSubCategory' : 'createSubCategory'}`)}
                  color="blue"
                  disabled={!isFormComplete}
                  onClick={onFinish}
                  loading={isLoadingCreateEditSubCategory}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

FormSubCategories.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape()),
  oneSubCategory: PropTypes.shape(),
  profile: PropTypes.shape(),
  isLoadingCategories: PropTypes.bool,
  isLoadingCreateEditSubCategory: PropTypes.bool,
};

FormSubCategories.defaultProps = {
  isEdit: false,
  isLoadingCreateEditSubCategory: false,
  data: {},
  profile: {},
  oneSubCategory: {},
  categories: [],
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
  isLoadingCategories: false
};

export default FormSubCategories;
