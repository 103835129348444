import React from 'react';

const Legend = () => (
  <div className="legend-container">
    <div className="legend-item">
      <div className="legend-color-box" style={{ backgroundColor: '#9A031E' }} />
      <span className="legend-text">Pedido</span>
    </div>
    <div className="legend-item">
      <div className="legend-color-box" style={{ backgroundColor: '#3559E0' }} />
      <span className="legend-text">Visita a Cliente</span>
    </div>
    <div className="legend-item">
      <div className="legend-color-box" style={{ backgroundColor: '#5C8374' }} />
      <span className="legend-text">Recorrido</span>
    </div>
  </div>
);

export default Legend;
