import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton } from 'antd';

const CustomButton = ({
  type, text, color, onClick, disabled = false, loading = false
}) => (
  <div className="button-container">
    <AntButton
      type={type}
      onClick={onClick}
      className={`button-container__button-${color}`}
      disabled={disabled}
      loading={loading}
    >
      {text}
    </AntButton>
  </div>
);

CustomButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

CustomButton.defaultProps = {
  type: 'default', // Puedes ajustar el valor predeterminado según el tipo de botón que desees.
  color: '',
  onClick: () => {},
  disabled: false,
  loading: false,
};

export default CustomButton;
