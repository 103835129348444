import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeaderProducts from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsProduct } from '../../../utils/parsers/tableParser';
import { CREATE_PRODUCTS_SCREEN_PATH, EDITE_PRODUCTS_SCREEN_PATH, PRODUCTS_SCREEN_PATH } from '../../../routes/paths';
import {
  deleteProduct, fetchAllProducts, fetchAllTypesProducts, fetchCategories, fetchSuppliers
} from '../../../utils/calls';
import { parseProductsList } from '../../../utils/parsers/parserProducts';
import HeaderSearch from '../../../components/header-search';
import { searchProducts } from '../../../utils/searchs';
import { withAuth } from '../../../hoc';

const BodyProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteneOneProduct, setDeleteOneProduct] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [productsTypes, setOneProductsTypes] = useState();
  const [categories, setCategories] = useState();
  const [suppliers, setSuppliers] = useState();
  const pageSize = 10;

  const getProducts = () => {
    setLoading(true);
    fetchAllProducts(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategories = () => {
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getSuppliers = () => {
    fetchSuppliers()
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getProductsTypes = () => {
    fetchAllTypesProducts()
      .then((response) => {
        setOneProductsTypes(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getProducts();
    getProductsTypes();
    getCategories();
    getSuppliers();
  }, []);

  useEffect(() => {
    if (suppliers && categories && productsTypes) {
      const inputsSearch = searchProducts(suppliers, productsTypes, categories);
      setInputs(inputsSearch);
    }
  }, [suppliers, categories, productsTypes]);

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (search) { getProducts(); }
  }, [currentPage, search]);

  useEffect(() => {
    getProducts();
  }, [currentPage]);

  const redirectCreate = () => {
    navigate(`${PRODUCTS_SCREEN_PATH}${CREATE_PRODUCTS_SCREEN_PATH}`);
  };

  const handleDelete = async (key) => {
    try {
      const deleteProductData = await deleteProduct(key);
      if (deleteProductData.status === 200) {
        setDeleteOneProduct(true);
        toast.success(t('toastSuccess.deleteProduct'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteProduct'));
    }
  };

  useEffect(() => {
    if (deleteneOneProduct) {
      getProducts();
      setDeleteOneProduct(false);
    }
  }, [deleteneOneProduct]);

  const redirect = (product) => {
    navigate(`${PRODUCTS_SCREEN_PATH}${EDITE_PRODUCTS_SCREEN_PATH}/${product.key}`);
  };

  const handleActionsColumn = (product) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(product);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteProduct')}
            onConfirm={() => handleDelete(product.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  return (
    <>
      <HeaderProducts />
      <div className="body-container__button">
        <Button
          text={t('button.createProduct')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />
      <div className="body-container__table">
        <Table
          columns={columnsProduct}
          loading={loading}
          dataSource={parseProductsList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1500 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyProducts);
