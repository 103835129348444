export const ROOT_PAGE_PATH = '/';
export const HOME_PAGE_PATH = '/home';

export const LOGIN_SCREEN_PATH = '/login';
export const LOGOUT_PATH = '/logout';
export const RESET_PASS_PATH = '/restaurar-contraseña';
export const RESET_PASS_INFO_PATH = '/info-contraseña';
export const CHANGE_PASS_PATH = '/new-password/:token';

export const USERS_SCREEN_PATH = '/usuarios';
export const CREATE_USERS_SCREEN_PATH = '/crear';
export const EDITE_USERS_SCREEN_PATH = '/editar';

export const ORDERS_SCREEN_PATH = '/pedidos';
export const DETAIL_ORDERS_SCREEN_PATH = '/detalle';
export const CREATE_ORDERS_SCREEN_PATH = '/crear';
export const EDITE_ORDERS_SCREEN_PATH = '/editar';

export const PRODUCTS_SCREEN_PATH = '/productos';
export const CREATE_PRODUCTS_SCREEN_PATH = '/crear';
export const EDITE_PRODUCTS_SCREEN_PATH = '/editar';

export const CLIENTS_SCREEN_PATH = '/clientes';
export const CREATE_CLIENTS_SCREEN_PATH = '/crear';
export const EDITE_CLIENTS_SCREEN_PATH = '/editar';

export const SUPPLIER_SCREEN_PATH = '/proveedores';
export const CREATE_SUPPLIER_SCREEN_PATH = '/crear';
export const EDITE_SUPPLIER_SCREEN_PATH = '/editar';

export const COMPANIES_SCREEN_PATH = '/empresas';
export const CREATE_COMPANIES_SCREEN_PATH = '/crear';
export const EDITE_COMPANIES_SCREEN_PATH = '/editar';

export const CATEGORIES_SCREEN_PATH = '/categorias';
export const CREATE_CATEGORIES_SCREEN_PATH = '/crear';
export const EDITE_CATEGORIES_SCREEN_PATH = '/editar';

export const SUB_CATEGORIES_SCREEN_PATH = '/sub-categorias';
export const CREATE_SUB_CATEGORIES_SCREEN_PATH = '/crear';
export const EDIT_SUB_CATEGORIES_SCREEN_PATH = '/editar';

export const TAXES_SCREEN_PATH = '/impuestos';
export const CREATE_TAXES_SCREEN_PATH = '/crear';
export const EDITE_TAXES_SCREEN_PATH = '/editar';

export const PROFILE_SCREEN_PATH = '/perfil';

export const FOLLOWUP_SCREEN_PATH = '/seguimiento';
