export const parseCategories = ({
  id,
  name,
  description,
  subCategories
}) => ({
  key: id,
  nombre: name,
  descripcion: description,
  subRubro: subCategories.map((subcategory) => `${subcategory?.name} - `)
});
export const parseCategoriesList = (list) => list?.map((camp) => parseCategories(camp));
