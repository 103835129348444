/* eslint-disable import/no-duplicates */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-empty */
import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Input, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LeftOutlined } from '@ant-design/icons';
import Logo from '../../../assets/logos/vortex.svg';
import { newPassword } from '../../../redux/actions/app';
import { setChangeAuthentication } from '../../../services/api';
import { LOGIN_SCREEN_PATH } from '../../../routes/paths';
import Button from '../../../components/button';
import { getValidationRules } from '../../../utils/validations';
import { validationPasswordKey } from '../../../utils/keys';

const { Item } = Form;
const { Password } = Input;
const { Title } = Typography;

const FormResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { token } = useParams();
  const dispatch = useDispatch();

  const handleBlur = async (name) => {
    try {
      await form.validateFields([name]);
    } catch (error) {}
  };

  useEffect(() => {
    localStorage.setItem('token', token);
    setChangeAuthentication();
  }, [token]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      dispatch(newPassword(token, values));
      navigate(LOGIN_SCREEN_PATH);
    } catch (error) {
      toast.error(t('login.error'));
    }
  };

  const redirect = () => {
    navigate(LOGIN_SCREEN_PATH);
  };

  return (
    <div className="login-container">
      <div className="login-container__logo">
        <img src={Logo} />
      </div>
      <div className="button-back">
        <Button
          onClick={redirect}
          text={<LeftOutlined />}
        />
      </div>
      <div className="login-container__form">
        <Title>{t('login.forgotPassword')}</Title>
        <Form form={form} onFinish={handleSubmit}>
          <Item
            name="password"
            label=""
            rules={getValidationRules(validationPasswordKey)}
          >
            <Password
              placeholder={t('login.enterNewPassword')}
              onBlur={() => handleBlur('password')}
            />
          </Item>
          <Item
            name="confirmPassword"
            label=""
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t('login.passwordMismatch'));
                },
              }),
            ]}
          >
            <Password
              placeholder={t('login.confirmNewPassword')}
              onBlur={() => handleBlur('confirmPassword')}
            />
          </Item>
          <Item>
            <Button
              onClick={handleSubmit}
              text={t('login.changePassword')}
              color="blue"
            />
          </Item>
        </Form>
      </div>
    </div>
  );
};

export default FormResetPassword;
