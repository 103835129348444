/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { DeleteFilled, EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import HeaderOrders from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsOrders, columnsOrdersAdmin } from '../../../utils/parsers/tableParser';
import {
  deleteOrder, fetchAllClients, fetchAllOrders, fetchCompanies, fetchSuppliers
} from '../../../utils/calls';
import { parseOrdersList } from '../../../utils/parsers/parserOrders';
import { DETAIL_ORDERS_SCREEN_PATH, ORDERS_SCREEN_PATH } from '../../../routes/paths';
import { searchOrders, searchOrdersCompany } from '../../../utils/searchs';
import HeaderSearch from '../../../components/header-search';
import { withAuth } from '../../../hoc';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';

const BodyOrders = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [deleteneOneOrder, setDeleteOneOrder] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [suppliers, setSuppliers] = useState();
  const [custommers, setCustommers] = useState();
  const [companies, setCompanies] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const pageSize = 10;
  const rol = localStorage.getItem(rolKey);
  const isRoleSuperAdmin = rol === Roles.SUPERADMIN;

  const getOrders = () => {
    setLoading(true);
    fetchAllOrders(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSuppliers = () => {
    fetchSuppliers()
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getClients = () => {
    fetchAllClients()
      .then((response) => {
        setCustommers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getOrders();
    getSuppliers();
    getClients();
    if (isRoleSuperAdmin) {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (suppliers && custommers) {
      const inputSearch = isRoleSuperAdmin ? searchOrdersCompany(custommers, companies) : searchOrders(custommers);
      setInputs(inputSearch);
    }
  }, [suppliers, custommers, companies]);

  const handleDelete = async (key) => {
    try {
      const deleteCompanyData = await deleteOrder(key);
      if (deleteCompanyData.status === 200) {
        setDeleteOneOrder(true);
        toast.success(t('toastSuccess.deleteOrder'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteOrder'));
    }
  };

  useEffect(() => {
    if (deleteneOneOrder) {
      getOrders();
      setDeleteOneOrder(false);
    }
  }, [deleteneOneOrder]);

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (search) { getOrders(); }
  }, [currentPage, search]);

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  const redirect = (order) => {
    navigate(`${ORDERS_SCREEN_PATH}${DETAIL_ORDERS_SCREEN_PATH}/${order.key}`);
  };

  const handleActionsColumn = (order) => (
    (
      <Space size="middle">
        <>
          <Button
            id="deleteButton"
            text={<EyeFilled />}
            color="edit"
            onClick={() => {
              redirect(order);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteOrder')}
            onConfirm={() => handleDelete(order.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  return (
    <>
      <HeaderOrders />
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />
      <div className="body-container__table">
        <div className="flex-end-container">
        <p className="subtotal-paginated">
            {t('label.subtotalPaginated')}: {data?.paginatedTotalSum} - 
        </p>
        <p className="total-sum">
            {t('label.totalSum')}: {data?.totalSum}
        </p>
        </div>
        <Table
          columns={isRoleSuperAdmin ? columnsOrdersAdmin : columnsOrders}
          loading={loading}
          dataSource={parseOrdersList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyOrders);
