import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import HeaderCompanies from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsCompany } from '../../../utils/parsers/tableParser';
import { deleteCompanies, fetchAllCompanies } from '../../../utils/calls';
import { parseCompaniesList } from '../../../utils/parsers/parserCompanies';
import { COMPANIES_SCREEN_PATH, CREATE_COMPANIES_SCREEN_PATH, EDITE_COMPANIES_SCREEN_PATH } from '../../../routes/paths';
import { searchCompanies } from '../../../utils/searchs';
import HeaderSearch from '../../../components/header-search';
import { withAuth } from '../../../hoc';

const BodyCompanies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [deleteneOneCompany, setDeleteOneCompany] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const pageSize = 10;

  const getCompanies = () => {
    setLoading(true);
    fetchAllCompanies(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getCompanies();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const redirectCreate = () => {
    navigate(`${COMPANIES_SCREEN_PATH}${CREATE_COMPANIES_SCREEN_PATH}`);
  };

  const handleDelete = async (key) => {
    try {
      const deleteCompanyData = await deleteCompanies(key);
      if (deleteCompanyData.status === 200) {
        setDeleteOneCompany(true);
        toast.success(t('toastSuccess.deleteCompany'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteCompany'));
    }
  };

  useEffect(() => {
    if (deleteneOneCompany) {
      getCompanies();
      setDeleteOneCompany(false);
    }
  }, [deleteneOneCompany]);

  const redirect = (company) => {
    navigate(`${COMPANIES_SCREEN_PATH}${EDITE_COMPANIES_SCREEN_PATH}/${company.key}`);
  };
  const handleActionsColumn = (company) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(company);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteCompany')}
            onConfirm={() => handleDelete(company.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (search) { getCompanies(); }
  }, [currentPage, search]);
  return (
    <>
      <HeaderCompanies />
      <div className="body-container__button">
        <Button
          text={t('button.createCompany')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={searchCompanies()} onSearch={handlePageSearch} />

      <div className="body-container__table">
        <Table
          columns={columnsCompany}
          loading={loading}
          dataSource={parseCompaniesList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyCompanies);
