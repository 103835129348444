export const typesTrackings = [
  {
    id: 'route',
    name: 'Recorrido'
  },
  {
    id: 'order',
    name: 'Pedido'
  },
  {
    id: 'customer',
    name: 'Visita a cliente'
  }
];
