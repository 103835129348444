/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import axios from 'axios';
import {
  accessTokenKey, refreshTokenKey, rolKey, tokenKey
} from '../utils/keys';
// import appConfig from '../config/appConfig';

// const { BASE_URL } = appConfig;
const { REACT_APP_URL_API } = process.env;
export const api = axios.create({
  baseURL: REACT_APP_URL_API,
});
// export const api = axios.create({
//   baseURL: BASE_URL,
// });

export const saveTokenAuthentication = ({ accessToken }, role) => {
  localStorage.setItem(accessTokenKey, accessToken);
  localStorage.setItem(rolKey, role);
};
export const removeTokens = () => {
  localStorage.clear();
};
api.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem(accessTokenKey)?.toString();
      if (token !== 'null' && token !== 'undefined' && token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const setAuthenticationRefresh = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(refreshTokenKey)}`;
};
export const setAuthentication = (accessToken) => {
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setChangeAuthentication = () => {
  api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(tokenKey)}`;
};
