const toCamelCase = (str) => str.split(' ').map((word, index) => {
  let parsedWord = word;
  parsedWord = parsedWord.replace('á', 'a');
  parsedWord = parsedWord.replace('Á', 'A');
  parsedWord = parsedWord.replace('é', 'e');
  parsedWord = parsedWord.replace('í', 'i');
  parsedWord = parsedWord.replace('ó', 'o');
  parsedWord = parsedWord.replace('ú', 'u');
  if (index === 0) {
    return parsedWord.toLowerCase();
  }
  return parsedWord.charAt(0).toUpperCase() + parsedWord.slice(1).toLowerCase();
}).join('');
const parserColumns = (columns) => columns.map((item) => ({
  title: item,
  dataIndex: toCamelCase(item),
  key: toCamelCase(item),
}));

export const columnsUsers = parserColumns(['Nombre', 'Apellido', 'Email', 'Celular', 'Rol', 'Dirección']);
export const columnsOrders = parserColumns(['Id pedido', 'Cliente', 'Fecha', 'Empleado', 'Descripcion', 'Total']);
export const columnsOrdersAdmin = parserColumns(['Empresa', 'Id pedido', 'Nº de pedido', 'Cliente', 'Fecha', 'Empleado', 'Descripcion', 'Total']);
export const columnsOrdersDetail = parserColumns(['Producto', 'Rubro', 'Tipo de producto', 'Nº de pedido', 'Precio', 'Cantidad', 'Sub total']);
export const columnsProduct = parserColumns(['SKU', 'Nombre', 'Descripcion', 'Stock', 'Precio', 'Tipo', 'Rubro', 'Proveedor', 'Empresa', 'Impuestos']);
export const columnsCategory = parserColumns(['Nombre', 'Descripcion', 'Sub rubro']);
export const columnsSubCategory = parserColumns(['Nombre', 'Descripcion', 'Rubro']);
export const columnsClient = parserColumns(['Nombre', 'Apellido', 'Celular', 'Dirección', 'Empresa']);
export const columnsSupplier = parserColumns(['Nombre', 'Celular', 'Dirección', 'Empresa']);
export const columnsCompany = parserColumns(['Nombre', 'Dirección', 'Celular']);
export const columnsTaxes = parserColumns(['Nombre', 'Porcentaje']);
export const columnsHome = parserColumns(['Nombre', 'Cantidad de venta', 'Fecha ultima venta']);
export const columnsTrackings = parserColumns(['Fecha', 'Tipo', 'Empresa', 'Empleado', 'Cliente', 'Pedido']);
