export const parseSuppliers = ({
  id,
  name,
  phone,
  address,
  company
}) => ({
  key: id,
  nombre: `${name ?? ''}`,
  celular: phone,
  direccion: address,
  empresa: company?.name

});

export const parseSuppliersList = (list) => list?.map((camp) => parseSuppliers(camp));
