import { translateRoleName } from './parserRoles';

export const parseUsers = ({
  id,
  name,
  lastName,
  phone,
  address,
  email,
  role,
}) => ({
  key: id,
  nombre: name,
  apellido: lastName || '-',
  celular: phone,
  direccion: address,
  email,
  rol: translateRoleName(role)

});

export const parseUsersList = (list) => list?.map((camp) => parseUsers(camp));
