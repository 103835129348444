import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import HeaderCompanies from '../header';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsTaxes } from '../../../utils/parsers/tableParser';
import { deleteTaxes, fetchAllTaxes } from '../../../utils/calls';
import { CREATE_TAXES_SCREEN_PATH, EDITE_TAXES_SCREEN_PATH, TAXES_SCREEN_PATH } from '../../../routes/paths';
import { parseTaxesList } from '../../../utils/parsers/parserTaxes';
import { withAuth } from '../../../hoc';

const BodyTaxes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [deleteneOneTaxe, setDeleteOneTaxe] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const pageSize = 10;

  const getTaxes = () => {
    setLoading(true);
    fetchAllTaxes(currentPage, pageSize)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getTaxes();
  }, [currentPage]);

  const redirectCreate = () => {
    navigate(`${TAXES_SCREEN_PATH}${CREATE_TAXES_SCREEN_PATH}`);
  };

  const handleDelete = async (key) => {
    try {
      const deleteTaxesData = await deleteTaxes(key);
      if (deleteTaxesData.status === 200) {
        setDeleteOneTaxe(true);
        toast.success(t('toastSuccess.deleteTaxe'));
      }
    } catch (error) {
      if (error.response && error.response.data.message === 'Error when deleting the item has associated.') {
        toast.error(t('toastError.errorAsociatedTaxesDelete'));
      } else {
        toast.error(t('toastError.deleteTaxe'));
      }
    }
  };

  useEffect(() => {
    if (deleteneOneTaxe) {
      getTaxes();
      setDeleteOneTaxe(false);
    }
  }, [deleteneOneTaxe]);

  const redirect = (taxe) => {
    navigate(`${TAXES_SCREEN_PATH}${EDITE_TAXES_SCREEN_PATH}/${taxe.key}`);
  };
  const handleActionsColumn = (taxe) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(taxe);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteTaxes')}
            onConfirm={() => handleDelete(taxe.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  return (
    <>
      <HeaderCompanies />
      <div className="body-container__button">
        <Button
          text={t('button.createTaxe')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <div className="body-container__table">
        <Table
          columns={columnsTaxes}
          loading={loading}
          dataSource={parseTaxesList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyTaxes);
