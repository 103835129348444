import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/title';

const HeaderFollowUp = () => {
  const { t } = useTranslation();

  return (
    <div className="container-header">
      <Title title={t('title.followUp')} />
    </div>
  );
};

export default HeaderFollowUp;
