/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Spin
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import Button from '../../../components/button';
import Title from '../../../components/title';
import InputPhone from '../../../components/input-phone';

const FormCompanies = ({
  isEdit, onEdit, onCreate, data, onChange, oneCompany, isLoadingCreateEditCompany
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  const redirect = () => {
    navigate(-1);
  };

  useEffect(() => {
    setIsFormComplete(
      data.name
      && data.phone
      && data.phone.length > 9
      && data.address
    );
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneCompany,
    });
  }, [oneCompany]);

  if (Object.keys(oneCompany).length === 0 && isEdit) {
    return <Spin size="large" fullscreen />;
  }

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeCompanies') : t('title.createCompanies')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="name"
                label={t('label.companyName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.name')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={isEdit ? oneCompany?.name : ''}
                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>

              <Form.Item
                name="phone"
                label={t('label.companyPhone')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.phone')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <InputPhone
                  onChange={onChange}
                  data={data}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>

              <Form.Item
                name="address"
                label={t('label.companyAddress')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.address')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('address', e.target.value)}
                  value={isEdit ? oneCompany?.address : ''}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="container-form__button">
                <div className="form-info-container__button-edit">
                  <Button
                    text={t(`button.${isEdit ? 'editeCompany' : 'createCompany'}`)}
                    color="blue"
                    disabled={!isFormComplete}
                    onClick={onFinish}
                    loading={isLoadingCreateEditCompany}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormCompanies.propTypes = {
  isEdit: PropTypes.bool,
  isLoadingCreateEditCompany: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  oneCompany: PropTypes.shape(),
};

FormCompanies.defaultProps = {
  isEdit: false,
  isLoadingCreateEditCompany: false,
  data: {},
  oneCompany: {},
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
};

export default FormCompanies;
