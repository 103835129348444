/* eslint-disable no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { Link } from 'react-router-dom';
import {
  CATEGORIES_SCREEN_PATH,
  CLIENTS_SCREEN_PATH, COMPANIES_SCREEN_PATH,
  FOLLOWUP_SCREEN_PATH,
  HOME_PAGE_PATH,
  ORDERS_SCREEN_PATH, PRODUCTS_SCREEN_PATH,
  PROFILE_SCREEN_PATH,
  SUB_CATEGORIES_SCREEN_PATH,
  SUPPLIER_SCREEN_PATH, USERS_SCREEN_PATH
} from '../routes/paths';

export const menu = [
  {
    key: HOME_PAGE_PATH,
    label: <Link to={HOME_PAGE_PATH} className="link">HOME</Link>
  },
  {
    key: USERS_SCREEN_PATH,
    label: <Link to={USERS_SCREEN_PATH} className="link">USUARIOS</Link>
  },
  {
    key: ORDERS_SCREEN_PATH,
    label: <Link to={ORDERS_SCREEN_PATH} className="link">PEDIDOS</Link>
  },
  {
    key: PRODUCTS_SCREEN_PATH,
    label: <Link to={PRODUCTS_SCREEN_PATH} className="link">PRODUCTOS</Link>
  },
  {
    key: CATEGORIES_SCREEN_PATH,
    label: <Link to={CATEGORIES_SCREEN_PATH} className="link">RUBRO</Link>
  },
  {
    key: SUB_CATEGORIES_SCREEN_PATH,
    label: <Link to={SUB_CATEGORIES_SCREEN_PATH} className="link">SUBRUBRO</Link>
  },
  {
    key: CLIENTS_SCREEN_PATH,
    label: <Link to={CLIENTS_SCREEN_PATH} className="link">CLIENTES</Link>
  },
  {
    key: SUPPLIER_SCREEN_PATH,
    label: <Link to={SUPPLIER_SCREEN_PATH} className="link">PROVEEDORES</Link>
  },
  {
    key: COMPANIES_SCREEN_PATH,
    label: <Link to={COMPANIES_SCREEN_PATH} className="link">EMPRESAS</Link>
  },
  {
    key: FOLLOWUP_SCREEN_PATH,
    label: <Link to={FOLLOWUP_SCREEN_PATH} className="link">SEGUIMIENTO</Link>
  },
  {
    key: PROFILE_SCREEN_PATH,
    label: <Link to={PROFILE_SCREEN_PATH} className="link">PERFIL</Link>
  }
];

export const menuMobile = [
  {
    key: HOME_PAGE_PATH,
    label: <Link to={HOME_PAGE_PATH} className="link">HOME</Link>
  },
  {
    key: USERS_SCREEN_PATH,
    label: <Link to={USERS_SCREEN_PATH} className="link">USUARIOS</Link>
  },
  {
    key: ORDERS_SCREEN_PATH,
    label: <Link to={ORDERS_SCREEN_PATH} className="link">PEDIDOS</Link>
  },
  {
    key: PRODUCTS_SCREEN_PATH,
    label: <Link to={PRODUCTS_SCREEN_PATH} className="link">PRODUCTOS</Link>
  },
  {
    key: CATEGORIES_SCREEN_PATH,
    label: <Link to={CATEGORIES_SCREEN_PATH} className="link">RUBRO</Link>
  },
  {
    key: SUB_CATEGORIES_SCREEN_PATH,
    label: <Link to={SUB_CATEGORIES_SCREEN_PATH} className="link">SUBRUBRO</Link>
  },
  {
    key: CLIENTS_SCREEN_PATH,
    label: <Link to={CLIENTS_SCREEN_PATH} className="link">CLIENTES</Link>
  },
  {
    key: SUPPLIER_SCREEN_PATH,
    label: <Link to={SUPPLIER_SCREEN_PATH} className="link">PROVEEDORES</Link>
  },
  {
    key: FOLLOWUP_SCREEN_PATH,
    label: <Link to={FOLLOWUP_SCREEN_PATH} className="link">SEGUIMIENTO</Link>
  },
  {
    key: PROFILE_SCREEN_PATH,
    label: <Link to={PROFILE_SCREEN_PATH} className="link">PERFIL</Link>
  }
];
