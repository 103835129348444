import { toast } from 'react-toastify';
import {
  CHANGE_PASS_PATH, LOGIN_API_PATH, RESET_PASSWORD_PATH, SET_USER_API_PATH
} from '../../services/path';
import {
  LOGIN, LOGOUT, RESET_PASS, SEND_EMAIL, SET_USER
} from './types';
import { api, saveTokenAuthentication, setAuthentication } from '../../services/api';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';

export const setUser = () => (dispatch) => dispatch({
  type: SET_USER,
  promise: api.get(SET_USER_API_PATH),
});

export const login = (data, callback) => (dispatch) => dispatch({
  type: LOGIN,
  promise: api.post(LOGIN_API_PATH, data),
  meta: {
    onSuccess: (res) => {
      setAuthentication(res.data.tokens.accessToken);
      saveTokenAuthentication(res.data.tokens, res.data.user.role);
      callback();
    },
    onFailure: (error) => {
      if (error?.response?.data?.message === 'Error. The user role is invalid.') {
        toast.error('Rol de ingreso no permitido.');
      } else {
        toast.error('Email o contraseña incorrecta');
      }
    }
  }
});

export const logout = () => (dispatch) => dispatch({
  type: LOGOUT,
});
export const resetStore = () => ({
  type: 'RESET'
});

export const sendEmail = (email, callback, handleFailure, handleSuccess) => (dispatch) => dispatch({
  type: SEND_EMAIL,
  promise: api.post(RESET_PASSWORD_PATH, {
    email
  }),
  meta: {
    onSuccess: () => {
      handleSuccess();
      callback();
    },
    onFailure: () => {
      handleFailure();
      callback();
    }

  }
});
export const newPassword = (token, newPasswordData) => (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return dispatch({
    type: RESET_PASS,
    promise: api.post(CHANGE_PASS_PATH, newPasswordData, config),
    meta: {
      onSuccess: (res) => {
        showSuccessMessage(res.data.message, dispatch);
      },
      onFailure: (error) => {
        showErrorMessage(error.response.data.message, dispatch);
      },
    },
  });
};
