import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Pagination, Popconfirm, Space } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Table from '../../../components/table';
import { columnsSubCategory } from '../../../utils/parsers/tableParser';
import {
  CREATE_SUB_CATEGORIES_SCREEN_PATH,
  EDIT_SUB_CATEGORIES_SCREEN_PATH,
  SUB_CATEGORIES_SCREEN_PATH
} from '../../../routes/paths';
import { deleteSubCategory, fetchAllSubCategories, fetchCategories } from '../../../utils/calls';
import HeaderSearch from '../../../components/header-search';
import { withAuth } from '../../../hoc';
import HeaderSubCategories from '../header';
import { parseSubCategoriesList } from '../../../utils/parsers/parserSubCategories';
import { searchSubCategory } from '../../../utils/searchs';

const BodySubCategories = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteneOneSubCategory, setDeleteOneSubCategory] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState(null);
  const pageSize = 10;

  const getSubCategories = () => {
    setLoading(true);
    fetchAllSubCategories(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCategories = () => {
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getSubCategories();
    getCategories();
  }, [currentPage], categories);

  const handleDelete = async (key) => {
    try {
      const deleteClientsData = await deleteSubCategory(key);
      if (deleteClientsData.status === 200) {
        setDeleteOneSubCategory(true);
        toast.success(t('toastSuccess.deleteSubCategory'));
      }
    } catch (error) {
      toast.error(t('toastError.deleteSubCategory'));
    }
  };

  useEffect(() => {
    if (deleteneOneSubCategory) {
      getSubCategories();
      setDeleteOneSubCategory(false);
    }
  }, [deleteneOneSubCategory]);

  const redirectCreate = () => {
    navigate(`${SUB_CATEGORIES_SCREEN_PATH}${CREATE_SUB_CATEGORIES_SCREEN_PATH}`);
  };

  const redirect = (subCategory) => {
    navigate(`${SUB_CATEGORIES_SCREEN_PATH}${EDIT_SUB_CATEGORIES_SCREEN_PATH}/${subCategory.key}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleActionsColumn = (subCategory) => (
    (
      <Space size="middle">
        <>
          <Button
            id="editButton"
            text={<EditFilled />}
            color="edit"
            onClick={() => {
              redirect(subCategory);
            }}
          />
          <Popconfirm
            title={t('popConfirm.deleteClient')}
            onConfirm={() => handleDelete(subCategory.key)}
            okText={t('popConfirm.okText')}
            cancelText={t('popConfirm.cancelText')}
          >
            <Button
              id="deleteButton"
              text={<DeleteFilled />}
              color="edit"
            />
          </Popconfirm>
        </>
      </Space>
    )
  );

  useEffect(() => {
    const inputsSearch = searchSubCategory(categories);
    setInputs(inputsSearch);
  }, [categories]);

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };
  useEffect(() => {
    if (search) { getSubCategories(); }
  }, [currentPage, search]);
  return (
    <>
      <HeaderSubCategories />
      <div className="body-container__button">
        <Button
          text={t('button.createSubCategory')}
          color="create"
          onClick={redirectCreate}
        />
      </div>
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />
      <div className="body-container__table">
        <Table
          columns={columnsSubCategory}
          loading={loading}
          dataSource={parseSubCategoriesList(data?.data)}
          actionsColumn={handleActionsColumn}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodySubCategories);
