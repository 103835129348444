/* eslint-disable*/
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select, Spin
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/button';
import { CATEGORIES_SCREEN_PATH, CREATE_CATEGORIES_SCREEN_PATH, EDITE_CATEGORIES_SCREEN_PATH } from '../../../routes/paths';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';

const FormCategories = ({
  isEdit, onEdit, onCreate, data, onChange, companies, oneCategory, profile, isLoadingCompanies, isLoadingCreateEditCategory
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { pathname } = useLocation();
  const createCategoriesPath = `${CATEGORIES_SCREEN_PATH}${CREATE_CATEGORIES_SCREEN_PATH}`;
  const editeCategoriesPath = `${CATEGORIES_SCREEN_PATH}${EDITE_CATEGORIES_SCREEN_PATH}`;
  const roleAdminCompany = Roles.ADMINCOMPANY;
  const rol = localStorage.getItem(rolKey);
  const isRoleAdminCompany = rol === roleAdminCompany;

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
    if (pathname === CATEGORIES_SCREEN_PATH) {
      onEdit();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    const formCompleteCondition = data.name && data.description;
    const isFormCompleteForNonAdminCompany = !isRoleAdminCompany ? formCompleteCondition && !!data.company : formCompleteCondition;
    setIsFormComplete(isFormCompleteForNonAdminCompany);
  }, [data, isRoleAdminCompany]);


  useEffect(() => {
    form.setFieldsValue({
      ...oneCategory,
      company: isRoleAdminCompany ? profile?.company?.id : oneCategory?.company?.id
    });

    if (isRoleAdminCompany) {
      form.setFields([
        {
          name: 'company',
          value: profile?.company?.id,
          errors: [],
        },
      ]);

    }
  }, [oneCategory, profile, form, isRoleAdminCompany]);

  return (
    <div className="container-form">
      <Form form={form}>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              name="name"
              label={t('label.productName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: pathname === createCategoriesPath,
                  message: `${t('label.name')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('name', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              name="description"
              label={t('label.descriptionName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: pathname === createCategoriesPath,
                  message: `${t('label.description')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        {
          !isRoleAdminCompany
            ? <Row>
              {
                (pathname === createCategoriesPath || pathname.includes(editeCategoriesPath)) && (
                  <Col span={isMobile ? 16 : 10}>
                    <Form.Item
                      name="company"
                      label={t('label.company')}
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        className="form-info-container__input"
                        placeholder={t('label.selectCompany')}
                        name="company"
                        onChange={(value) => handleChange('company', value)}
                        disabled={isRoleAdminCompany}
                        notFoundContent={isLoadingCompanies ? <Spin /> : null}
                      >
                        {companies?.map((company) => (
                          <Select.Option key={company.id} value={company.id}>
                            {company.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )
              }
            </Row> : null
        }
        <Row gutter={16}>
          <Col span={24}>
            <div className="container-form__button">
              <div className="form-info-container__button-edit">
                <Button
                  className="form-info-container__submit-button"
                  text={t(`button.${isEdit ? 'editeCategory' : 'createCategory'}`)}
                  color="blue"
                  disabled={!isFormComplete}
                  onClick={onFinish}
                  loading={isLoadingCreateEditCategory}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

FormCategories.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape()),
  oneCategory: PropTypes.shape(),
  profile: PropTypes.shape(),
  isLoadingCompanies: PropTypes.bool,
  isLoadingCreateEditCategory: PropTypes.bool,
};

FormCategories.defaultProps = {
  isEdit: false,
  isLoadingCreateEditCategory: false,
  data: {},
  profile: {},
  oneCategory: {},
  companies: [],
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
  isLoadingCompanies: false
};

export default FormCategories;
