/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import FormDetailOrder from './form';
import Title from '../../../components/title';
import { fetchOneOrder } from '../../../utils/calls';
import Button from '../../../components/button';
import { withAuth } from '../../../hoc';;

const OrderDetails = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getOneOrder = () => {
    fetchOneOrder(id)
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getOneOrder();
  }, []);

  const redirect = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-header">
        <Title title={t('title.ordersDetail')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <FormDetailOrder
        data={data}
      />
    </>
  );
};

export default withAuth(OrderDetails);
