/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import FormCategories from '../form';
import { CATEGORIES_SCREEN_PATH } from '../../../routes/paths';
import {
  createCategories, editeCategories, fetchCompanies, fetchOneCategory
} from '../../../utils/calls';
import Title from '../../../components/title';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';
import Button from '../../../components/button';
import { withAuth } from '../../../hoc';

const CreateEditCategories = ({ isEdit }) => {
  const navigate = useNavigate();
  const rol = localStorage.getItem(rolKey);
  const { id } = useParams();
  const { t } = useTranslation();
  const [companies, setCompanies] = useState();
  const [oneCategory, setOneCategory] = useState();
  const { user } = useSelector((state) => state.appReducer);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingCreateEditCategory, setIsLoadingCreateEditCategory] = useState(false);
  const [form, setForm] = useState({
    name: null,
    description: null,
    company: null
  });

  const redirect = () => {
    navigate(CATEGORIES_SCREEN_PATH);
  };
  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const getCompanies = (setLoading = () => { }) => {
    setLoading(true);
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  const handleCreate = () => {
    const formParser = {
      ...form,
      company: user?.company?.id
    };
    setIsLoadingCreateEditCategory(true);
    createCategories(rol === Roles.ADMINCOMPANY ? formParser : form)
      .then(() => {
        toast.success(t('toastSuccess.createCategory'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createCategory'));
        setIsLoadingCreateEditCategory(false);
      });
  };

  const handleEdit = () => {
    setIsLoadingCreateEditCategory(true);
    editeCategories(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editCategory'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editCategory'));
        setIsLoadingCreateEditCategory(false);
      });
  };

  const getOneCategory = () => {
    if (isEdit) {
      fetchOneCategory(id)
        .then((response) => {
          setOneCategory(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getCompanies(setIsLoadingCompanies);
    if (isEdit) {
      getOneCategory();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneCategory ? oneCategory?.name : '',
        description: oneCategory ? oneCategory?.description : '',
        company: oneCategory ? oneCategory?.company?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneCategory]);

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeCategory') : t('title.createCategory')} />
      </div>
      <div className="button-back">
        <Button
          onClick={redirect}
          color="back"
          text={<LeftOutlined />}
        />
      </div>
      <FormCategories
        data={form}
        isEdit={isEdit}
        onChange={handleChange}
        onCreate={handleCreate}
        companies={companies}
        onEdit={handleEdit}
        oneCategory={oneCategory}
        profile={user}
        isLoadingCompanies={isLoadingCompanies}
        isLoadingCreateEditCategory={isLoadingCreateEditCategory}
      />
    </>
  );
};

CreateEditCategories.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditCategories.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditCategories);
