export const searchOrders = (customers) => [{
  type: 'select',
  name: 'customer',
  text: 'Cliente',
  options: customers
},
{
  type: 'rangePicker',
  name: 'dateRange',
  text: 'Rango de Fechas'
}];

export const searchOrdersCompany = (customers, companies) => [{
  type: 'select',
  name: 'customer',
  text: 'Cliente',
  options: customers
},
{
  type: 'rangePicker',
  name: 'dateRange',
  text: 'Rango de Fechas'
},
{
  type: 'select',
  name: 'company',
  text: 'Empresa',
  options: companies
}];

export const searchUsers = (roles) => [{
  type: 'input',
  name: 'keyword',
}, {
  type: 'select',
  name: 'role',
  text: 'Rol',
  options: roles
}];

export const searchProducts = (suppliers, types, categories) => [{
  type: 'input',
  name: 'keyword'
}, {
  type: 'select',
  name: 'supplier',
  text: 'Proveedor',
  options: suppliers
}, {
  type: 'select',
  name: 'productType',
  text: 'Tipo',
  options: types
}, {
  type: 'select',
  name: 'category',
  text: 'Rubro',
  options: categories
}, {
  type: 'input',
  name: 'sku',
  text: 'SKU',
}
];

export const searchCustommers = () => [{
  type: 'input',
  name: 'keyword'
}];

export const searchCompanies = () => [{
  type: 'input',
  name: 'keyword'
}];

export const searchCategory = () => [{
  type: 'input',
  name: 'keyword'
}];
export const searchSubCategory = (categories) => [{
  type: 'input',
  name: 'keyword'
}, {
  type: 'select',
  name: 'category',
  text: 'Rubro',
  options: categories
}];

export const searchSuppliers = () => [{
  type: 'input',
  name: 'keyword'
}];

export const searchSuppliersCompany = (companies) => [{
  type: 'input',
  name: 'keyword'
}, {
  type: 'select',
  name: 'company',
  text: 'Empresa',
  options: companies
}];

export const searchTrackings = (customers, employees, types) => [{
  type: 'select',
  name: 'customer',
  text: 'Cliente',
  options: customers
}, {
  type: 'select',
  name: 'type',
  text: 'Tipo',
  options: types
}, {
  type: 'select',
  name: 'user',
  text: 'Empleado',
  options: employees
},
{
  type: 'rangePicker',
  name: 'dateRange',
  text: 'Rango de Fechas'
}
];

export const searchTrackingsCompanies = (customers, employees, types, companies) => [{
  type: 'select',
  name: 'customer',
  text: 'Cliente',
  options: customers
}, {
  type: 'select',
  name: 'type',
  text: 'Tipo',
  options: types
}, {
  type: 'select',
  name: 'user',
  text: 'Empleado',
  options: employees
}, {
  type: 'select',
  name: 'company',
  text: 'Empresa',
  options: companies
},
{
  type: 'rangePicker',
  name: 'dateRange',
  text: 'Rango de Fechas'
}
];

export const searchMap = (customers, types) => [
  {
    type: 'select',
    name: 'customer',
    text: 'Cliente',
    options: customers
  },
  {
    type: 'select',
    name: 'type',
    text: 'Tipo',
    options: types
  },
  {
    type: 'rangePicker',
    name: 'dateRange',
    text: 'Rango de Fechas'
  },
];
