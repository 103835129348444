/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';
import {
  DatePicker, Collapse, Row, Col, Button
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import locale from 'antd/lib/date-picker/locale/es_ES';
import debounce from 'lodash.debounce';
import InputSearch from '../input-search';
import SelectSearch from '../select-search';

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

const HeaderSearch = ({ onSearch, inputs }) => {
  const [search, setSearch] = useState();
  const [rangeDate, setRangeDate] = useState();

  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 1300),
    []
  );

  const handleSearch = (name, value) => {
    let newSearch;
    if (name === 'price' && Array.isArray(value)) {
      const [minPrice, maxPrice] = value;
      newSearch = {
        ...search,
        [name]: { minPrice, maxPrice },
      };
    } else if (name === 'dateRange' && Array.isArray(value)) {
      const [startDate, endDate] = value;
      newSearch = {
        ...search,
        startDate: moment(startDate.$d).format('YYYY-MM-DD'),
        endDate: moment(endDate.$d).format('YYYY-MM-DD'),
      };
      setRangeDate(value);
    } else {
      newSearch = {
        ...search,
        [name]: value,
        startDate: null,
        endDate: null,
      };
      setRangeDate(null);
    }
    setSearch(newSearch);
    debouncedSearch(newSearch);
  };

  const colSpan = {
    xs: 24, sm: 12, md: 8, lg: 6
  };
  const renderInputs = () => {
    const inputsInRows = [];
    let currentRow = [];
    inputs.forEach(({
      name, text, type, options
    }) => {
      const inputComponent = type === 'input' ? (
        <InputSearch
          value={search ? search[name] : ''}
          placeholder={text ? `Buscar por ${text}` : 'Buscar'}
          onSearch={(e) => handleSearch(name, e)}
        />
      ) : type === 'select' ? (
        <SelectSearch
          value={search ? search[name] : ''}
          options={options}
          text={text}
          onSelectChange={(e) => handleSearch(name, e)}
        />
      ) : type === 'rangePicker' ? (
        <RangePicker
          style={{ width: '130%' }}
          format="DD/MM/YYYY"
          value={rangeDate || { startDate: null, endDate: null }}
          onChange={(dates) => handleSearch(name, dates)}
          locale={locale}
        />
      ) : null;

      currentRow.push(
        <Col className="keon" key={name}>
          {inputComponent}
        </Col>
      );

      if (currentRow.length === 4) {
        inputsInRows.push(
          <Row gutter={16} key={inputsInRows.length}>
            {currentRow}
          </Row>
        );
        currentRow = [];
      }
    });
    if (currentRow.length > 0) {
      while (currentRow.length < 4) {
        currentRow.push(
          <Col
            key={`empty-${currentRow.length}`}
            {...colSpan}
          />
        );
      }

      inputsInRows.push(
        <Row
          gutter={16}
          key={inputsInRows.length}
        >
          {currentRow}
        </Row>
      );
    }
    return inputsInRows;
  };

  const resetFilters = () => {
    const initialSearchState = {};
    inputs.forEach(({ name, type }) => {
      if (name === 'dateRange') {
        setRangeDate(null);
      }
      if (type === 'priceRange') {
        initialSearchState[name] = { minPrice: 0, maxPrice: 1000 };
      } else {
        initialSearchState[name] = null;
      }
    });
    setSearch(initialSearchState);
    onSearch(initialSearchState);
  };

  return (
    <div className="container-search">
      <Collapse accordion>
        <Panel header="Filtros" key="filter">
          <div className="container-search__filters">
            <Button className="container-search__filters-button" onClick={resetFilters}>Restablecer filtros</Button>
            {renderInputs()}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

HeaderSearch.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default HeaderSearch;
