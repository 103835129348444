/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import Button from '../../../components/button';
import Title from '../../../components/title';
import { getValidationRules } from '../../../utils/validations';
import { rolKey, validationEmailKey, validationPasswordKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import InputPhone from '../../../components/input-phone';

const FormUsers = ({
  isEdit, onEdit, onCreate, data, onChange, oneUser, roles,
  companies, isLoadingCompanies, isLoadingRoles, isLoadingCreateEditUser
}) => {
  const rol = localStorage.getItem(rolKey);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const roleAdminCompany = Roles.ADMINCOMPANY;
  const isRolEqualRoleAdminCompany = rol === roleAdminCompany;
  const roleSuperAdmin = Roles.SUPERADMIN;
  const isRolEqualRoleSuperadmin = rol === roleSuperAdmin;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };
  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isRolEqualRoleAdminCompany) {
      const isPasswordRequired = pathname.includes('crear');
      setIsFormComplete(
        data.name
        && data.lastName
        && data.phone
        && data.phone.length > 9
        && ((!isPasswordRequired) || (isPasswordRequired && data?.password?.length > 7))
        && data.address
        && data.email
      );
    } else {
      const isPasswordRequired = pathname.includes('crear');
      setIsFormComplete(
        data.name
        && data.lastName
        && data.phone
        && data.phone.length > 9
        && data.address
        && data.email
        && data.role
        && data.company
        && ((!isPasswordRequired) || (isPasswordRequired && data.password))
        && ((!isPasswordRequired) || (isPasswordRequired && data?.password?.length > 7))
      );
    }
  }, [data]);

  useEffect(() => {
    const isEmailValid = form.getFieldError('email').length === 0;
    const isPasswordValid = form.getFieldError('password').length === 0;

    setIsFormValid(isEmailValid && isPasswordValid);
  }, [form, form.getFieldError('email')]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneUser,
      company: oneUser?.company?.name ? oneUser?.company?.name : oneUser?.companyEmployee?.name
    });
  }, [oneUser]);

  const redirect = () => {
    navigate(-1);
  };

  if (Object.keys(oneUser).length === 0 && isEdit) {
    return <Spin size="large" fullscreen />;
  }
  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeUser') : t('title.createUser')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form-user">
        <Form form={form} layout="horizontal">
          <div className={isMobile ? 'mobile-form-user__direction' : 'container-form-user__direction'}>
            <Form.Item
              className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
              name="name"
              label={t('label.userName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `${t('label.name')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                onChange={(e) => handleChange('name', e.target.value)}
                value={isEdit ? oneUser.name : ''}
              />
            </Form.Item>
            <Form.Item
              className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
              name="lastName"
              label={t('label.userLastName')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `${t('label.lastName')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('lastName', e.target.value)}
                value={isEdit ? oneUser.lastName : ''}
              />
            </Form.Item>
            <Form.Item
              className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
              name="phone"
              label={t('label.userPhone')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `${t('label.phone')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <InputPhone
                onChange={onChange}
                data={data}
              />
            </Form.Item>
            <Form.Item
              className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
              name="address"
              label={t('label.userAddress')}
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: `${t('label.address')} ${t('basic.isRequired')}`,
                },
              ]}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('address', e.target.value)}
                value={isEdit ? oneUser.address : ''}
              />
            </Form.Item>
            {isRolEqualRoleSuperadmin && <>
              <Form.Item
                className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
                name="role"
                label={t('label.role')}
                labelCol={{ span: 24 }}
                rules={isRolEqualRoleSuperadmin && [
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  className="form-info-container__input"
                  placeholder={t('label.selectRole')}
                  name="role"
                  onChange={(value) => handleChange('role', value)}
                  value={isEdit ? oneUser?.role : ''}
                  notFoundContent={isLoadingRoles ? <Spin /> : null}
                >
                  {roles
                    .filter((role) => (role.name !== 'Administrador'))
                    .map((role) => (
                      <Select.Option key={role.id} value={role.id}>
                        {role.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
                name="company"
                label={t('label.companySelect')}
                labelCol={{ span: 24 }}
                rules={isRolEqualRoleSuperadmin && [
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  className="form-info-container__input"
                  placeholder={t('label.selectCompany')}
                  name="company"
                  onChange={(value) => handleChange('company', value)}
                  value={isEdit ? oneUser?.company?.name : ''}
                  notFoundContent={isLoadingCompanies ? <Spin /> : null}
                >
                  {companies.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>

              </Form.Item>
            </>
            }
            <Form.Item
              className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
              name="email"
              label={t('label.userEmail')}
              labelCol={{ span: 24 }}
              rules={getValidationRules(validationEmailKey)}
            >
              <Input
                className="form-info-container__input"
                onChange={(e) => handleChange('email', e.target.value)}
                value={isEdit ? oneUser.email : ''}
              />
            </Form.Item>
            {!isEdit
              && (
                <Form.Item
                  className={isMobile ? 'mobile-form-user__item' : 'container-form-user__item'}
                  name="password"
                  label={t('label.userPassword')}
                  type="password"
                  labelCol={{ span: 24 }}
                  rules={getValidationRules(validationPasswordKey)}
                >
                  <Input.Password
                    onChange={(e) => handleChange('password', e.target.value)}
                  />
                </Form.Item>
              )}
          </div>
          <div className="container-form__button">
            <div className="form-info-container__button-edit">
              <Button
                className="form-info-container__submit-button"
                text={t(`button.${isEdit ? 'editeUser' : 'createUser'}`)}
                color="blue"
                disabled={!isFormComplete || !isFormValid}
                onClick={onFinish}
                loading={isLoadingCreateEditUser}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

FormUsers.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  oneUser: PropTypes.shape(),
  roles: PropTypes.arrayOf(PropTypes.shape()),
  companies: PropTypes.arrayOf(PropTypes.shape()),
  isLoadingCompanies: PropTypes.bool,
  isLoadingRoles: PropTypes.bool,
  isLoadingCreateEditUser: PropTypes.bool,
};

FormUsers.defaultProps = {
  isEdit: false,
  isLoadingCompanies: false,
  isLoadingRoles: false,
  isLoadingCreateEditUser: false,
  data: {},
  oneUser: {},
  companies: [],
  roles: [],
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
};

export default FormUsers;
