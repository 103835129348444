import {
  LOGIN,
  LOGOUT,
  SET_FLASH_MESSAGE,
  SET_USER,
} from '../actions/types';
import { handleAction } from '../../utils/handleAction';

const initialState = {
  isLoading: false,
  isLogged: false,
  token: null,
  flashMessageContent: {
    text: '',
    type: '',
    isVisible: false
  },
  user: {
    nombre: '',
    role: ''
  }
};
const appReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload }),
        success: (prevState) => ({
          ...prevState,
          user: payload.data
        }),
      });
    case SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessageContent: {
          ...state.flashMessageContent,
          ...action.flashMessageContent
        }
      };
    case LOGIN:
      return handleAction(state, action, {
        failure: (prevState) => ({ ...prevState, error: payload, isLoading: false }),
        success: (prevState) => ({
          ...prevState,
          isLogged: true,
          isLoading: true,
          token: payload.data.tokens,
          user: payload.data.user
        }),
      });
    case LOGOUT:
      return {
        ...initialState,
        isLoading: false,
        isLogged: false
      };
    default:
      return state;
  }
};
export default appReducer;
