import React from 'react';
import { useTranslation } from 'react-i18next';
import Title from '../../components/title';
import FormProfile from './form';

const Profile = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container-header">
        <Title title={t('title.profile')} />
      </div>
      <FormProfile />
    </>
  );
};

export default Profile;
