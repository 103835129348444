import { message } from 'antd';
import { SET_FLASH_MESSAGE } from './types';

message.config({
  duration: 2,
  maxCount: 3,
  rtl: true,
  prefixCls: 'my-message',
});

export const setFlashMessage = (flashMessageContent) => (dispatch) => {
  const error = 'error';
  const success = 'success';
  dispatch({
    type: SET_FLASH_MESSAGE,
    flashMessageContent
  });
  if (flashMessageContent.type === error) {
    message.error(flashMessageContent.text);
  }
  if (flashMessageContent.type === success) {
    message.success(flashMessageContent.text);
  }
};
