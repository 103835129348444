import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { createCompanies, editeteCompanies, fetchOneCompany } from '../../../utils/calls';
import FormCompanies from '../form';
import { COMPANIES_SCREEN_PATH } from '../../../routes/paths';
import { withAuth } from '../../../hoc';

const CreateEditCompanies = ({ isEdit }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneCompany, setOneCompany] = useState();
  const [isLoadingCreateEditCompany, setisLoadingCreateEditCompany] = useState(false);
  const [form, setForm] = useState({
    name: '',
    phone: '',
    address: '',
  });

  const redirect = () => {
    navigate(COMPANIES_SCREEN_PATH);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const handleCreate = () => {
    setisLoadingCreateEditCompany(true);
    createCompanies(form)
      .then(() => {
        toast.success(t('toastSuccess.createCompany'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createCompany'));
        setisLoadingCreateEditCompany(false);
      });
  };

  const handleEdit = () => {
    setisLoadingCreateEditCompany(true);
    editeteCompanies(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editCompany'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editCompany'));
        setisLoadingCreateEditCompany(false);
      });
  };

  const getOneCompany = () => {
    if (isEdit) {
      fetchOneCompany(id)
        .then((response) => {
          setOneCompany(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  useEffect(() => {
    getOneCompany();
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneCompany ? oneCompany?.name : '',
        phone: oneCompany ? oneCompany?.phone : '',
        address: oneCompany ? oneCompany?.address : '',
      };
      setForm(dataEdit);
    }
  }, [oneCompany]);

  return (
    <FormCompanies
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onCreate={handleCreate}
      onEdit={handleEdit}
      oneCompany={oneCompany}
      isLoadingCreateEditCompany={isLoadingCreateEditCompany}
    />
  );
};

CreateEditCompanies.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditCompanies.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditCompanies);
