export const SET_VERSION = 'SET_VERSION';

export const SET_USER = 'SET_USER';
export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const SEND_EMAIL = 'SEND_EMAIL';
export const RESET_PASS = 'RESET_PASS';
export const USER_VERIFIED = 'USER_VERIFIED';

export const SET_SELECTED_KEY = 'SET_SELECTED_KEY';
export const RESET_SELECTED_KEY = 'SET_SELECTED_KEY';

export const SET_MESSEGE = 'SET_MESSEGE';
export const GET_MESSEGE = 'GET_MESSEGE';
export const GET_CHATS = 'GET_CHATS';
export const CLEAN_MESSEGE = 'CLEAN_MESSEGE';
