/*eslint-disable*/

import { api } from '../services/api';

// PROFILE

export const fetchProfile = async () => {
  const response = await api.get(`/profile`,);
  return response;
};

// COMPANIES

export const createCompanies = async (form) => {
  const response = await api.post(`/companies`, form);
  return response;
};

export const fetchOneCompany = async (id) => {
  const response = await api.get(`/companies/${id}`,);
  return response;
};

export const fetchAllCompanies = async (page, limit, search) => {
  const response = await api.get(`/companies`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const fetchCompanies = async () => {
  const response = await api.get(`/companies`, {
  });
  return response;
};

export const editeteCompanies = async (id, form) => {
  const response = await api.patch(`/companies/${id}`, form);
  return response;
};

export const deleteCompanies = async (id) => {
  const response = await api.delete(`/companies/${id}`);
  return response;
};

// USERS

export const createUsers = async (form) => {
  const response = await api.post(`/auth/register`, form);
  return response;
};

export const fetchOneUser = async (id) => {
  const response = await api.get(`/users/${id}`,);
  return response;
};

export const fetchAllUsers = async (page, limit, search) => {
  const response = await api.get(`/users`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const fetchAllEmployees = async () => {
  const response = await api.get(`/employees`);
  return response;
};

export const editeteUsers = async (id, form) => {
  const response = await api.patch(`/users/${id}`, form);
  return response;
};

export const deleteUser = async (id) => {
  const response = await api.delete(`/users/${id}`);
  return response;
};

// ORDERS

export const createOrder = async (form) => {
  const response = await api.post(`/orders`, form);
  return response;
};

export const fetchOneOrder = async (id) => {
  const response = await api.get(`/orders/${id}`,);
  return response;
};

export const fetchAllOrders = async (page, limit, search) => {
  const response = await api.get(`/orders`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const editeOrder = async (id, form) => {
  const response = await api.patch(`/orders/${id}`, form);
  return response;
};

export const deleteOrder = async (id) => {
  const response = await api.delete(`/orders/${id}`);
  return response;
};

// PRODUCT

export const createProduct = async (file) => {
  const response = api.post(`/products`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
};

export const fetchOneProduct = async (id) => {
  const response = await api.get(`/products/${id}`,);
  return response;
};

export const fetchAllProducts = async (page, limit, search) => {
  const response = await api.get(`/products`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const editeProduct = async (id, form) => {
  const response = await api.patch(`/products/${id}`, form);
  return response;
};

export const deleteProduct = async (id) => {
  const response = await api.delete(`/products/${id}`);
  return response;
};

// CLIENTS
export const createClients = async (form) => {
  const response = await api.post(`/customers`, form);
  return response;
};

export const fetchOneClient = async (id) => {
  const response = await api.get(`/customers/${id}`,);
  return response;
};

export const fetchAllClients = async (page, limit, search) => {
  const response = await api.get(`/customers`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const editeClient = async (id, form) => {
  const response = await api.patch(`/customers/${id}`, form);
  return response;
};

export const deleteClients = async (id) => {
  const response = await api.delete(`/customers/${id}`);
  return response;
};

// SUPPLIER

export const createSuppliers = async (form) => {
  const response = await api.post(`/suppliers`, form);
  return response;
};

export const fetchOneSupplier = async (id) => {
  const response = await api.get(`/suppliers/${id}`,);
  return response;
};

export const fetchSuppliers = async () => {
  const response = await api.get(`/suppliers`, {
  });
  return response;
};

export const fetchSuppliersByCompany = async (id) => {
  const response = await api.get(`/suppliers-company/${id}`, {
  });
  return response;
};

export const fetchAllSuppliers = async (page, limit, search) => {
  const response = await api.get(`/suppliers`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const editeSuppliers = async (id, form) => {
  const response = await api.patch(`/suppliers/${id}`, form);
  return response;
};

export const deleteSuppliers = async (id) => {
  const response = await api.delete(`/suppliers/${id}`);
  return response;
};

// TYPE PRODUCT

export const fetchAllTypesProducts = async () => {
  const response = await api.get(`/product-types`, {
  });
  return response;
};

// CATEGORIES

export const createCategories = async (form) => {
  const response = await api.post(`/categories`, form);
  return response;
};

export const editeCategories = async (id, form) => {
  const response = await api.patch(`/categories/${id}`, form);
  return response;
};

export const fetchOneCategory = async (id) => {
  const response = await api.get(`/categories/${id}`,);
  return response;
};

export const fetchOneSubCategory = async (id) => {
  const response = await api.get(`/sub-categories/${id}`,);
  return response;
};

export const createSubCategories = async (form) => {
  const response = await api.post(`/sub-categories`, form);
  return response;
};

export const editeSubCategories = async (id, form) => {
  const response = await api.patch(`/sub-categories/${id}`, form);
  return response;
};

export const fetchAllCategories = async (page, limit, search) => {
  const response = await api.get(`/categories`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const fetchCategories = async () => {
  const response = await api.get(`/categories`, {
  });
  return response;
};

export const fetchCategoriesByCompany = async (id) => {
  const response = await api.get(`/categories-company/${id}`, {
  });
  return response;
};

export const deleteCategory = async (id) => {
  const response = await api.delete(`/categories/${id}`, {});
  return response;
};

export const deleteSubCategory = async (id) => {
  const response = await api.delete(`/sub-categories/${id}`);
  return response;
};

export const fetchAllSubCategories = async (page, limit, search) => {
  const response = await api.get(`/sub-categories`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

// ROLES

export const fetchRoles = async () => {
  const response = await api.get(`/roles`, {
  });
  return response;
};

// TAXES

export const createTaxes = async (form) => {
  const response = await api.post(`/taxes`, form);
  return response;
};

export const editeTaxes = async (id, form) => {
  const response = await api.patch(`/taxes/${id}`, form);
  return response;
};

export const fetchOneTaxe = async (id) => {
  const response = await api.get(`/taxes/${id}`,);
  return response;
};

export const fetchAllTaxes = async (page, limit) => {
  const response = await api.get(`/taxes`, {
    params: {
      page,
      limit,
    },
  });
  return response;
};

export const deleteTaxes = async (id) => {
  const response = await api.delete(`/taxes/${id}`);
  return response;
};

export const fetchTaxesByCompany = async (id) => {
  const response = await api.get(`/taxes-company/${id}`, {
  });
  return response;
};

// HOME TIME

export const fetchHome = async (date) => {
  const response = await api.get(`/dashboard/${date}`, {
  });
  return response;
};

// TRACKING

export const fetchAllTrackings = async (page, limit, search) => {
  const response = await api.get(`/trackings`, {
    params: {
      page,
      limit,
      ...search
    },
  });
  return response;
};

export const fetchAllTrackingsMap = async (user, search = {}) => {
  const response = await api.get(`/trackings-map/${user}`, {
    params: { ...search }
  });
  return response;
};
