/* eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select, Spin
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Title from '../../../components/title';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import InputPhone from '../../../components/input-phone';

const FormSuppliers = ({
  isEdit, onEdit, onCreate, data, onChange,
  oneSupplier, companies, profile, isLoadingCompanies, isLoadingCreateEditSupplier
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const rol = localStorage.getItem(rolKey);
  const navigate = useNavigate();
  const roleAdminCompany = Roles.ADMINCOMPANY;
  const roleSuperAdmin = Roles.SUPERADMIN;
  const isRolEqualRoleSuperadmin = rol === roleSuperAdmin;

  const onFinish = () => {
    if (isEdit) {
      onEdit();
    } else {
      onCreate();
    }
  };

  const handleChange = (name, value) => {
    onChange({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    if (rol === roleAdminCompany) {
      setIsFormComplete(
        data.name
        && data.phone
        && data.phone.length > 9
        && data.address
      );
    } else {
      setIsFormComplete(
        data.name
        && data.phone
        && data.phone.length > 9
        && data.address
        && data.company
      );
    }
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneSupplier,
      company: oneSupplier?.company?.name
    });
  }, [oneSupplier]);

  const redirect = () => {
    navigate(-1);
  };

  if (Object.keys(oneSupplier).length === 0 && isEdit) {
    return <Spin size="large" fullscreen />;
  }

  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeSupplier') : t('title.createSupplier')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="name"
                label={t('label.supplierName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.name')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={isEdit ? oneSupplier.name : ''}

                />
              </Form.Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="address"
                label={t('label.clientAddress')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.address')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  className="form-info-container__input"
                  onChange={(e) => handleChange('address', e.target.value)}
                  value={isEdit ? oneSupplier.address : ''}

                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Form.Item
                name="phone"
                label={t('label.supplierPhone')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.phone')} ${t('basic.isRequired')}`,
                  }
                  ]}
              >
                <InputPhone
                  onChange={onChange}
                  data={data}
                />
              </Form.Item>
            </Col>
            {isRolEqualRoleSuperadmin
              && (
                <Col span={isMobile ? 16 : 10}>
                  <Form.Item
                    name="company"
                    label={t('label.company')}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      className="form-info-container__input"
                      placeholder={rol === roleAdminCompany ? profile?.company?.name : t('label.selectCompany')}
                      name="company"
                      onChange={(value) => handleChange('company', value)}
                      disabled={rol === roleAdminCompany}
                      notFoundContent={isLoadingCompanies ? <Spin /> : null}
                    >
                      {companies?.map((company) => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="container-form__button">
                <div className="form-info-container__button-edit">
                  <Button
                    className="form-info-container__submit-button"
                    text={t(`button.${isEdit ? 'editeSupplier' : 'createSupplier'}`)}
                    color="blue"
                    disabled={!isFormComplete}
                    onClick={onFinish}
                    loading={isLoadingCreateEditSupplier}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormSuppliers.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  oneSupplier: PropTypes.shape(),
  companies: PropTypes.arrayOf(PropTypes.shape()),
  profile: PropTypes.shape(),
  isLoadingCompanies: PropTypes.bool,
  isLoadingCreateEditSupplier: PropTypes.bool
};

FormSuppliers.defaultProps = {
  isEdit: false,
  isLoadingCreateEditSupplier: false,
  data: {},
  profile: {},
  oneSupplier: {},
  companies: [],
  isLoadingCompanies: false,
  onChange: () => { },
  onCreate: () => { },
  onEdit: () => { },
};

export default FormSuppliers;
