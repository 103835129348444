export const parseTaxes = ({
  id,
  name,
  porcentage
}) => ({
  key: id,
  nombre: name,
  porcentaje: `${Math.round(porcentage * 100)} %`,
});

export const parseTaxesList = (list) => list?.map((camp) => parseTaxes(camp));
