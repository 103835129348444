/* eslint-disable no-empty */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LeftOutlined, UserOutlined } from '@ant-design/icons';
import { LOGIN_SCREEN_PATH, RESET_PASS_INFO_PATH } from '../../routes/paths';
import Button from '../../components/button';
import { sendEmail } from '../../redux/actions/app';
import Logo from '../../assets/logos/vortex.svg';

const { Title, Text } = Typography;

const ResetPassword = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Item } = Form;
  const [disabled, setDisabled] = useState(true);
  const rules = [
    {
      required: true,
      message: t('login.pleaseMail'),
    },
    {
      type: 'email',
      message: t('login.pleaseValidMail'),
    },
  ];
  const handleDisabled = () => {
    setDisabled(!disabled);
  };

  const handleFailure = () => {
    toast.error(t('basic.emailIncorrect'));
  };

  const handleSuccess = () => {
    toast.success(t('basic.emailCorrect'));
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      handleDisabled();
      const values = form.getFieldsValue();
      dispatch(sendEmail(values.email, handleDisabled, handleFailure, handleSuccess));
      navigate(RESET_PASS_INFO_PATH);
    } catch (errorInfo) {}
  };

  const redirect = () => {
    navigate(LOGIN_SCREEN_PATH);
  };

  const handleFormValuesChange = () => {
    const isValid = form.getFieldsError().every((field) => field.errors.length === 0);
    setDisabled(!isValid);
  };

  return (
    <div className="login-container">
      <div className="login-container__logo">
        <img src={Logo} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="login-container__form">
        <Title>{t('login.forgotPassword')}</Title>
        <Text type="secondary">{t('login.instructions')}</Text>
        <Form form={form} onValuesChange={handleFormValuesChange}>
          <div className="container-login__body">
            <Item
              name="email"
              rules={rules}
            >
              <Input placeholder={t('login.emailAddress')} prefix={<UserOutlined />} />
            </Item>
            <Item>
              <Button
                onClick={handleSubmit}
                text={t('login.validateEmail')}
                color="blue"
                disabled={disabled}
              />
            </Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
