import { setFlashMessage } from '../redux/actions/flashMessage';

export const showSuccessMessage = (text, dispatch) => {
  dispatch(setFlashMessage({
    text,
    type: 'success',
    isVisible: true
  }));
};
export const showErrorMessage = (text, dispatch) => {
  dispatch(setFlashMessage({
    text,
    type: 'error',
    isVisible: true
  }));
};

export const getAntdConfig = (theme) => ({
  token: {
    colorPrimaryHover: theme.colors.secondary,
    colorPrimary: theme.colors.secondary,
    fontFamily: 'Sk-Modernist',
  },
});
