import { formatDateTime } from './dateparser';
import { parseTypes } from './parserType';

const positionString = (lat, long) => {
  const formattedLat = parseFloat(lat).toFixed(2);
  const formattedLong = parseFloat(long).toFixed(2);

  return `[${formattedLat} ; ${formattedLong}]`;
};
export const parseTracking = ({
  createdAt,
  lat,
  long,
  type,
  company,
  user,
  order,
  customer,

}) => ({
  key: user?.id,
  posicion: positionString(lat, long),
  tipo: parseTypes(type),
  empleado: `${user?.name} ${user?.lastName}`,
  empresa: company?.name,
  cliente: customer?.name ? `${customer.name} ${customer.lastName}` : '-',
  pedido: order?.total ? `$${order.total}` : '-',
  fecha: formatDateTime(createdAt)

});
export const parseTrackingList = (list) => list?.map((camp) => parseTracking(camp));
