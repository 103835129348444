/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const InputSearch = ({ placeholder, onSearch, value }) => {
  const handleChange = (e) => {
    onSearch(e?.target?.value !== '' ? e?.target?.value : null);
  };
  return (
    <Input
      className="input-search"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      enterButton={<SearchOutlined />}
    />
  );
};

InputSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default InputSearch;
