/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { combineReducers } from 'redux';
import { LOGOUT } from '../actions/types.js';
import { removeTokens } from '../../services/api.js';
import appReducer from './appReducer.js';
import userKeysReducer from './userKeysReducer.js';
import messegeReducer from './messegeReducer.js';

const MainReducer = combineReducers({
  appReducer,
  userKeysReducer,
  messegeReducer,
});
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    removeTokens();
    state = undefined;
  }

  if (action.type === 'RESET') {
    state = undefined;
  }
  return MainReducer(state, action);
};
export default rootReducer;
