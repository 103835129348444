export const parseProducts = ({
  id,
  sku,
  name,
  description,
  price,
  stock,
  category,
  company,
  supplier,
  productType,
  taxes
}) => ({
  key: id,
  sku,
  nombre: name,
  descripcion: description,
  precio: `$${price}`,
  stock,
  rubro: category?.name,
  tipo: productType?.name,
  empresa: company?.name,
  proveedor: supplier?.name,
  impuestos: taxes.map((taxe) => `${taxe?.tax?.name}`)

});
export const parseProductsList = (list) => list?.map((camp) => parseProducts(camp));
