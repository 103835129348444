import {
  Col, Form, Input, Row
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const FormProfile = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.appReducer);

  return (
    <div className="container-form">
      <Form>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.userName')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.name}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.userLastName')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.lastName}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.userPhone')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.phone}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.userAddress')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.address}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.role')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.role}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.companySelect')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.company?.name ? user?.company?.name : '-'}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={isMobile ? 16 : 10}>
            <Form.Item
              label={t('label.userEmail')}
              labelCol={{ span: 24 }}
            >
              <Input
                value={user?.email}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormProfile;
