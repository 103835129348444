import { getDate } from './dateparser';

export const parseHomeProduct = ({
  id,
  name,
  count,
  lastOrder
}) => ({
  key: id,
  nombre: name,
  cantidadDeVenta: count,
  fechaUltimaVenta: getDate(lastOrder)
});
export const parseHomeProductList = (list) => list?.map((camp) => parseHomeProduct(camp));
