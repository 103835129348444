/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-undef */
import { useState } from 'react';
import {
  Button, Drawer, Layout, Menu
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { rolKey } from '../../utils/keys';
import { Roles } from '../../utils/enums/roles';
import Logo from '../../assets/logos/vortex.svg';
import LogoMini from '../../assets/logos/vortex-mini.svg';
import Icon from '../icons/index';
import { menu, menuMobile } from '../../utils/menuNav';
import { LOGIN_SCREEN_PATH } from '../../routes/paths';
import { logout, resetStore } from '../../redux/actions/app';
import ButtonComponent from '../button';

const { Content, Sider } = Layout;

const LayoutContainer = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { t } = useTranslation();
  const rol = localStorage.getItem(rolKey);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.appReducer);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetStore());
    navigate(LOGIN_SCREEN_PATH);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  function getItem(label, path, iconName, children) {
    return {
      path,
      icon: <Icon name={iconName} size={20} />,
      children: collapsed ? null : children,
      label: collapsed ? null : label,
    };
  }

  const superAdmin = [
    getItem(t('collapsed.home'), '/home', 'Home'),
    getItem(t('collapsed.users'), '/usuarios', 'User'),
    getItem(t('collapsed.orders'), '/pedidos', 'Orders'),
    getItem(t('collapsed.products'), '/productos', 'Product'),
    getItem(t('collapsed.categories'), '/categorias', 'Category'),
    getItem(t('collapsed.subCategories'), '/sub-categorias', 'SubCategory'),
    getItem(t('collapsed.clients'), '/clientes', 'Client'),
    getItem(t('collapsed.suppliers'), '/proveedores', 'Supplier'),
    getItem(t('collapsed.companies'), '/empresas', 'Company'),
    getItem(t('collapsed.taxes'), '/impuestos', 'Taxes'),
    getItem(t('collapsed.followUp'), '/seguimiento', 'Mevements'),
  ];

  const adminCompany = [
    getItem(t('collapsed.home'), '/home', 'Home'),
    getItem(t('collapsed.users'), '/usuarios', 'User'),
    getItem(t('collapsed.orders'), '/pedidos', 'Orders'),
    getItem(t('collapsed.products'), '/productos', 'Product'),
    getItem(t('collapsed.categories'), '/categorias', 'Category'),
    getItem(t('collapsed.subCategories'), '/sub-categorias', 'SubCategory'),
    getItem(t('collapsed.clients'), '/clientes', 'Client'),
    getItem(t('collapsed.suppliers'), '/proveedores', 'Supplier'),
    getItem(t('collapsed.taxes'), '/impuestos', 'Company'),
    getItem(t('collapsed.followUp'), '/seguimiento', 'Mevements'),
  ];

  return (

    isMobile ? (
      <Layout style={{ minHeight: '100vh' }}>
        <div className="container-mobile">
          <img src={Logo} alt="Logo" />
          <Button
            className="menu-burger"
            icon={<MenuOutlined />}
            onClick={handleMenuOpen}
            type="text"
            style={{
              color: 'white',
              backgroundColor: 'transparent',
              top: 0,
              right: 0,
            }}
          />
          <Drawer
            title="Menu"
            placement="right"
            onClose={handleMenuClose}
            open={isMenuOpen}
          >
            <Menu
              selectedKeys={pathname}
              mode="vertical"
              items={user.role === Roles.SUPERADMIN ? menu : menuMobile}
              onClick={handleMenuItemClick}
            />

            <ButtonComponent
              text={t('collapsed.logout')}
              color="logout"
              onClick={handleLogout}
            />
          </Drawer>
        </div>
        <Content>{children}</Content>

      </Layout>
    ) : (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <div className="layout-container-logo">
            {!collapsed
              ? <img src={Logo} alt="Logo" style={{ marginBlockEnd: '1.2rem' }} />
              : <img className="layout-container-logo__mini" src={LogoMini} alt="Logo" />}
          </div>
          <Menu theme="light" mode="inline">
            {rol === Roles.SUPERADMIN && (superAdmin.map((item) => (
              <Menu.Item title="" key={item.path} icon={item.icon}>
                {item.path === '/logout' ? (

                  <ButtonComponent
                    text={t('collapsed.logout')}
                    color="logout"
                    onClick={handleLogout}
                  />
                ) : (
                  <Link to={item.path}>{item.label}</Link>
                )}
              </Menu.Item>
            )))}
            {rol === Roles.ADMINCOMPANY && (adminCompany.map((item) => (
              <Menu.Item title="" key={item.path} icon={item.icon}>
                {item.path === '/logout' ? (

                  <ButtonComponent
                    text={t('collapsed.logout')}
                    color="logout"
                    onClick={handleLogout}
                  />
                ) : (
                  <Link to={item.path}>{item.label}</Link>
                )}
              </Menu.Item>
            )))}
          </Menu>
        </Sider>
        <Layout>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    )
  );
};

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutContainer;
