export const parseClients = ({
  id,
  name,
  lastName,
  phone,
  address,
  company
}) => ({
  key: id,
  nombre: name,
  apellido: lastName,
  celular: phone,
  direccion: address,
  empresa: company?.name

});

export const parseClientsList = (list) => list?.map((camp) => parseClients(camp));
