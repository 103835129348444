/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import Button from '../../../components/button';
import Logo from '../../../assets/logos/vortex.svg';
import { LOGIN_SCREEN_PATH } from '../../../routes/paths';

const { Title, Text } = Typography;

const InfoResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirect = () => {
    navigate(LOGIN_SCREEN_PATH);
  };

  return (
    <div className="login-container">
      <div className="login-container__logo">
        <img src={Logo} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} type="primary" text={<LeftOutlined />} />
      </div>
      <div className="login-container__form">
        <Title>{t('login.forgotPassword')}</Title>
        <Text>{t('login.mailSender')}</Text>
      </div>
    </div>
  );
};

export default InfoResetPassword;
