import {
  CLEAN_MESSEGE, GET_CHATS, GET_MESSEGE, SET_MESSEGE
} from '../actions/types';

const initialState = {
  lastMessage: null,
  lastMessageTime: null,
  chats: [],
  messages: []
};

const messegeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSEGE:
      return {
        ...state,
        lastMessage: action.payload.lastMessage,
        lastMessageTime: action.payload.lastMessageTime,
      };
    case GET_MESSEGE:
      return {
        ...state,
        messages: action.payload.messages,
      };
    case GET_CHATS:
      return {
        ...state,
        chats: action.payload.messages,
      };
    case CLEAN_MESSEGE:
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};

export default messegeReducer;
