/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createClients, editeClient, fetchCompanies, fetchOneClient, fetchProfile
} from '../../../utils/calls';
import FormClients from '../form';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import { withAuth } from '../../../hoc';

const CreateEditClients = ({ isEdit }) => {
  const rol = localStorage.getItem(rolKey);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oneClient, setOneClient] = useState();
  const [companies, setCompanies] = useState();
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [isLoadingCreateEditClient, setIsLoadingCreateEditClient] = useState(false);
  const [profile, setProfile] = useState();
  const [form, setForm] = useState({
    name: '',
    lastName: '',
    phone: '',
    address: '',
    company: ''
  });

  const redirect = () => {
    navigate(-1);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const handleCreate = () => {
    const formParser = {
      ...form,
      company: profile?.company?.id
    };
    setIsLoadingCreateEditClient(true);
    createClients(rol === Roles.ADMINCOMPANY ? formParser : form)
      .then(() => {
        toast.success(t('toastSuccess.createClient'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.createClient'));
        setIsLoadingCreateEditClient(false);
      });
  };

  const handleEdit = () => {
    setIsLoadingCreateEditClient(true);
    editeClient(id, form)
      .then(() => {
        toast.success(t('toastSuccess.editClient'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editClient'));
        setIsLoadingCreateEditClient(false);
      });
  };

  const getOneClient = () => {
    if (isEdit) {
      fetchOneClient(id)
        .then((response) => {
          setOneClient(response.data);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  const getCompanies = (setLoading = () => {}) => {
    setLoading(true);
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => setLoading(false));
  };

  const getProfile = () => {
    fetchProfile()
      .then((response) => {
        setProfile(response?.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    if (rol === Roles.SUPERADMIN) {
      getCompanies(setIsLoadingCompanies);
    }
    if (rol === Roles.ADMINCOMPANY) {
      getProfile();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      getOneClient();
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneClient ? oneClient?.name : '',
        lastName: oneClient ? oneClient?.lastName : '',
        phone: oneClient ? oneClient?.phone : '',
        address: oneClient ? oneClient?.address : '',
        company: oneClient ? oneClient?.company?.id : '',
      };
      setForm(dataEdit);
    }
  }, [oneClient]);

  return (
    <FormClients
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onCreate={handleCreate}
      onEdit={handleEdit}
      oneClient={oneClient}
      companies={companies}
      profile={profile}
      isLoadingCompanies={isLoadingCompanies}
      isLoadingCreateEditClient={isLoadingCreateEditClient}
    />
  );
};

CreateEditClients.propTypes = {
  isEdit: PropTypes.bool,
};

CreateEditClients.defaultProps = {
  isEdit: true,
};

export default withAuth(CreateEditClients);
