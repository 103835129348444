/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

const InputPhone = ({ onChange, data }) => {

    const handleChange = (value) => {
        onChange({
            ...data,
            phone: value,
        });
    };

    const handleInput = (e) => {
        e.target.value = e.target.value.replace(/\D/g, '');
    };

    return (
        <Input
            className="form-info-container__input"
            value={data.phone}
            placeholder='999 999 9999'
            onInput={handleInput}
            onChange={(e) => handleChange(e.target.value)}
            maxLength={11}
        />
    );
};

InputPhone.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default InputPhone;
