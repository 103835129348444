export const parseSubCategories = ({
  id,
  name,
  description,
  category
}) => ({
  key: id,
  nombre: name,
  descripcion: description,
  rubro: category?.name
});
export const parseSubCategoriesList = (list) => list?.map((camp) => parseSubCategories(camp));
