/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import Home from '../../assets/logos/sider/home.svg';
import User from '../../assets/logos/sider/user.svg';
import Orders from '../../assets/logos/sider/orders.svg';
import Company from '../../assets/logos/sider/company.svg';
import Product from '../../assets/logos/sider/product.svg';
import Category from '../../assets/logos/sider/category.svg';
import SubCategory from '../../assets/logos/sider/subcategory.svg';
import Client from '../../assets/logos/sider/client.svg';
import Supplier from '../../assets/logos/sider/supplier.svg';
import Close from '../../assets/logos/sider/close.svg';
import Taxes from '../../assets/logos/sider/taxes.svg';
import Mevements from '../../assets/logos/sider/mevements.svg';

const iconImages = {
  Home,
  User,
  Orders,
  Company,
  Product,
  Category,
  SubCategory,
  Client,
  Supplier,
  Close,
  Taxes,
  Mevements
};
const Icon = ({ name, size = 50 }) => (<img src={iconImages[`${name}`]} width={size} alt={name} />);
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default Icon;
