/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Input, Row, Select
} from 'antd';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Title from '../../../components/title';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';

const { Item } = Form;
const FormProducts = ({
  isEdit,
  onEdit,
  isLoadingEditProduct,
  data,
  onChange,
  oneProduct,
  productsTypes,
  companies,
  categories,
  suppliers,
  taxes,
  taxesDefault,
  profile
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const navigate = useNavigate();
  const rol = localStorage.getItem(rolKey);
  const roleAdminCompany = Roles.ADMINCOMPANY;

  const findCommonAndRemovedElements = (list1, list2) => {
    const taxes = list2.filter((element) => !list1.includes(element));
    const deletedTaxes = list1.filter((element) => !list2.includes(element));

    const taxesChanged = {
      taxes,
      deletedTaxes,
    };
    return taxesChanged;
  };

  const onFinish = async () => {
    const formData = await findCommonAndRemovedElements(taxesDefault, data.taxes);
    await onEdit(formData);
  };

  const handleChange = (name, value) => {
    if (name === 'company') {
      const formParser = {
        ...data,
        company: value,
        category: null,
        supplier: null,
        taxes: null,
      };
      onChange(formParser);
      form.setFieldsValue(formParser);
    } else {
      onChange({
        ...data,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setIsFormComplete(
      data.name
      && data.description
      && data.productType
      && data.supplier
      && data?.taxes
      && data.stock
    );
  }, [data]);

  useEffect(() => {
    form.setFieldsValue({
      ...oneProduct,
      productType: oneProduct?.productType?.id,
      supplier: oneProduct?.supplier?.id,
      company: oneProduct?.company?.name,
      category: oneProduct?.category?.id,
      taxes: oneProduct?.taxes ? oneProduct.taxes.map((taxe) => taxe?.tax?.id) : [],
    });
  }, [oneProduct]);

  const redirect = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="container-header">
        <Title title={isEdit ? t('title.editeProduct') : t('title.createProduct')} />
      </div>
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <div className="container-form">
        <Form form={form}>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="name"
                label={t('label.productName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.name')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleChange('name', e.target.value)}
                  value={isEdit ? oneProduct.name : ''}

                />
              </Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="description"
                label={t('label.descriptionName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.description')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleChange('description', e.target.value)}
                  value={isEdit ? oneProduct.description : ''}

                />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="price"
                label={t('label.priceName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.price')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleChange('price', e.target.value)}
                  value={isEdit ? oneProduct.price : ''}

                />
              </Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="stock"
                label={t('label.stockName')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: `${t('label.stock')} ${t('basic.isRequired')}`,
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleChange('stock', e.target.value)}
                  value={isEdit ? oneProduct.stock : ''}
                />
              </Item>
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="productType"
                label={t('label.productType')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={t('label.selecProductType')}
                  name="productType"
                  onChange={(value) => handleChange('productType', value)}
                  value={isEdit ? oneProduct?.productType?.name : ''}
                >
                  {productsTypes.map((productType) => (
                    <Select.Option key={productType.id} value={productType.id}>
                      {productType.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="company"
                label={t('label.company')}
                labelCol={{ span: 24 }}
                rules={rol !== roleAdminCompany && [
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={rol === roleAdminCompany ? profile?.company?.name : t('label.selecCompany')}
                  name="company"
                  onChange={(value) => handleChange('company', value)}
                  disabled={rol === roleAdminCompany}

                >
                  {companies.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="category"
                label={t('label.category')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={t('label.selecCategory')}
                  name="category"
                  onChange={(value) => handleChange('category', value)}
                  disabled={!data.company}
                >
                  {categories.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="supplier"
                label={t('label.supplier')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={t('label.selecSupplier')}
                  name="supplier"
                  onChange={(value) => handleChange('supplier', value)}
                  disabled={!data.company}
                >
                  {suppliers.map((supplier) => (
                    <Select.Option key={supplier.id} value={supplier.id}>
                      {supplier.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>

          </Row>
          <Row gutter={16}>
            <Col span={isMobile ? 16 : 10}>
              <Item
                name="taxes"
                label={t('label.taxes')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder={t('label.selecTaxes')}
                  name="taxes"
                  onChange={(value) => handleChange('taxes', value)}
                  mode="tags"
                  disabled={!data.company}
                  tokenSeparators={[',']}
                >
                  {taxes.map((taxe) => (
                    <Select.Option key={taxe.id} value={taxe.id}>
                      {taxe.name}
                    </Select.Option>
                  ))}
                </Select>
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div className="container-form__button">
                <div className="form-info-container__button-edit">
                  <Button
                    className="form-info-container__submit-button"
                    type="submit"
                    text={t(`button.${isEdit ? 'editeProduct' : 'createProduct'}`)}
                    color={!isFormComplete ? 'blueDisable' : 'blue'}
                    disabled={!isFormComplete}
                    onClick={onFinish}
                    loading={isLoadingEditProduct}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

FormProducts.propTypes = {
  isEdit: PropTypes.bool,
  isLoadingEditProduct: PropTypes.bool,
  data: PropTypes.shape(),
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  oneProduct: PropTypes.shape(),
  productsTypes: PropTypes.arrayOf(PropTypes.shape()),
  companies: PropTypes.arrayOf(PropTypes.shape()),
  categories: PropTypes.arrayOf(PropTypes.shape()),
  suppliers: PropTypes.arrayOf(PropTypes.shape()),
  taxes: PropTypes.arrayOf(PropTypes.shape()),
  taxesDefault: PropTypes.arrayOf(PropTypes.shape()),
  profile: PropTypes.shape(),
};

FormProducts.defaultProps = {
  isEdit: false,
  isLoadingEditProduct: false,
  data: {},
  profile: {},
  oneProduct: {},
  productsTypes: [],
  companies: [],
  categories: [],
  suppliers: [],
  taxes: [],
  taxesDefault: [],
  onChange: () => {},
  onEdit: () => {},
};

export default FormProducts;
