/* eslint-disable react/react-in-jsx-scope */
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getValidationRules } from '../../../utils/validations';
import { validationEmailKey, validationPasswordKey } from '../../../utils/keys';
import Button from '../../../components/button';

const { Item } = Form;
const { Password } = Input;
const FormLogin = ({ form, onSubmmit }) => {
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.appReducer);
  const [passwordLength, setPasswordLength] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const isEmailValid = form.getFieldError('email').length === 0;
    const isPasswordValid = form.getFieldError('password').length === 0 && passwordLength > 7;
    setIsFormValid(isEmailValid && isPasswordValid);
  }, [form, passwordLength]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      const newPassword = e.target.value;
      setPasswordLength(newPassword.length);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    await onSubmmit(formData);
  };

  return (
    <div className="login-container__inputs">
      <Form form={form} layout="vertical">
        <div className="login-container__content">
          <Item name="email" rules={getValidationRules(validationEmailKey)}>
            <Input
              className="login-ant-input-affix-wrapper"
              name="email"
              value={formData.email}
              placeholder={t('login.emailAddress')}
              prefix={<UserOutlined />}
              onChange={handleChange}
            />
          </Item>
          <Item name="password" rules={passwordLength > 4 && getValidationRules(validationPasswordKey)}>
            <Password
              className="login-ant-input-affix-wrapper"
              name="password"
              value={formData.password}
              type="password"
              placeholder={t('login.password')}
              prefix={<LockOutlined />}
              onChange={handleChange}
            />
          </Item>
          <Item>
            <Button
              text={t('login.login')}
              loading={isLoading}
              onClick={handleSubmit}
              color="blue"
              htmlType="submit"
              disabled={!isFormValid}
            />
          </Item>
        </div>
      </Form>
    </div>
  );
};

FormLogin.propTypes = {
  form: PropTypes.shape().isRequired,
  onSubmmit: PropTypes.func,
};

FormLogin.defaultProps = {
  onSubmmit: () => {}
};

export default FormLogin;
