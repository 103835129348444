/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Source,
  Layer
} from 'react-map-gl';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Space, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Pin from './pin';
import { fetchAllClients, fetchAllTrackingsMap } from '../../../utils/calls';
import 'mapbox-gl/dist/mapbox-gl.css';
import { parseTypes } from '../../../utils/parsers/parserType';
import { formatDateTime } from '../../../utils/parsers/dateparser';
import HeaderFollowUp from '../header';
import { searchMap } from '../../../utils/searchs';
import { typesTrackings } from '../../../utils/typesTracking';
import HeaderSearch from '../../../components/header-search';
import Legend from './legend';
import Button from '../../../components/button';

const TOKEN = 'pk.eyJ1Ijoic2VsZW5hOTQiLCJhIjoiY2xsZzdhZDdjMDAxODNtbWtkc29tczljcyJ9.i4x3PbzVkvq5gkxU6Mdoxw';
const { Text } = Typography;

const TrackingMap = () => {
  const [popupInfo, setPopupInfo] = useState(null);
  const [data, setData] = useState();
  const [routeData, setRouteData] = useState([]);
  const [pointsIntermedio, setPointsIntermedio] = useState([]);
  const [pins, setPins] = useState([]);
  const [loading, setLoading] = useState(null);
  const [search, setSearch] = useState(null);
  const [inputs, setInputs] = useState([]);
  const [custommers, setCustommers] = useState();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const getData = () => {
    setLoading(true);
    setData([]);
    fetchAllTrackingsMap(id, search)
      .then((response) => {
        setData(response.data || []);
        if (response.data.length > 0) {
          const pinsData = response.data?.map((point, index) => (
            <Marker
              key={point?.id}
              longitude={point?.long}
              latitude={point?.lat}
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                setPopupInfo(point);
              }}
            >
              <Pin color={point?.type} number={index + 1} />
              {point.point_count && (
              <div style={{
                position: 'absolute', top: '-20px', left: '10px', backgroundColor: '#ffffff', padding: '5px', borderRadius: '5px'
              }}
              >
                {point.point_count}
              </div>
              )}
            </Marker>
          ));
          setPins(pinsData);
          const routeCoordinates = response.data.map((point) => [point.long, point.lat]);
          setRouteData({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: routeCoordinates,
            },
          });
          const sumaLatitud = routeCoordinates.reduce((sum, coord) => sum + coord[1], 0);
          const sumaLongitud = routeCoordinates.reduce((sum, coord) => sum + coord[0], 0);

          const promedioLatitud = sumaLatitud / routeCoordinates.length;
          const promedioLongitud = sumaLongitud / routeCoordinates.length;

          setPointsIntermedio([promedioLongitud, promedioLatitud]);
        } else {
          setPins([]);
          setPointsIntermedio([]);
          setRouteData({
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          });
        }
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getClients = () => {
    fetchAllClients()
      .then((response) => {
        setCustommers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getData();
    getClients();
  }, []);

  const popUp = ({
    type, user, customer, order, createdAt
  }) => {
    if (type === 'order') {
      return (
        <Space align="start" direction="vertical">
          <Text strong>{`Tipo: ${parseTypes(type)}`}</Text>
          {order && <Text strong>{`Fecha ${formatDateTime(order.createdAt)}`}</Text>}
          {user && <Text strong>{`Vendedor: ${user.name} ${user.lastName}`}</Text>}
        </Space>
      );
    }
    if (type === 'customer') {
      return (
        <Space align="start" direction="vertical">
          <Text strong>{`Tipo: ${parseTypes(type)}`}</Text>
          {customer && <Text strong>{`Cliente: ${customer.name}`}</Text>}
          {user && <Text strong>{`Vendedor: ${user.name} ${user.lastName}`}</Text>}
          {createdAt && <Text strong>{`Fecha ${formatDateTime(createdAt)}`}</Text>}
        </Space>
      );
    }
    return (
      <Space align="start" direction="vertical">
        <Text strong>{`Tipo: ${parseTypes(type)}`}</Text>
        {user && <Text strong>{`Vendedor: ${user.name} ${user.lastName}`}</Text>}
        {createdAt && <Text strong>{`Fecha ${formatDateTime(createdAt)}`}</Text>}
      </Space>
    );
  };

  const handleSearch = (text) => {
    setSearch(text);
  };
  useEffect(() => {
    if (custommers) {
      const inputSearch = searchMap(custommers, typesTrackings);
      setInputs(inputSearch);
    }
  }, [custommers]);

  useEffect(() => {
    if (search) {
      getData();
    }
  }, [search]);

  const redirect = () => {
    navigate(-1);
  };

  return (
    <>
      <HeaderFollowUp />
      <div className="button-back">
        <Button onClick={redirect} color="back" text={<LeftOutlined />} />
      </div>
      <HeaderSearch inputs={inputs} onSearch={handleSearch} />
      {!loading && pins && (
      <Map
        style={{ width: '95%', height: '40rem', margin: 'auto' }}
        initialViewState={{
          latitude: pointsIntermedio[1] || -26.82,
          longitude: pointsIntermedio[0] || -65.29,
          zoom: 12,
          bearing: 0,
          pitch: 0
        }}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
        mapboxAccessToken={TOKEN}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl />
        <Legend />
        {pins.length > 0 && pins}
        {routeData && (
        <Source type="geojson" data={routeData}>
          <Layer
            id="route"
            type="line"
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
            paint={{
              'line-color': '#888',
              'line-width': 4,
            }}
          />
        </Source>
        )}

        {popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.long)}
          latitude={Number(popupInfo.lat)}
          onClose={() => setPopupInfo(null)}
        >
          {popUp(popupInfo)}
        </Popup>
        )}
      </Map>
      )}
    </>
  );
};

export default TrackingMap;
