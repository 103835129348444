/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LOGIN_SCREEN_PATH, PROFILE_SCREEN_PATH } from '../../routes/paths';
import { logout, resetStore } from '../../redux/actions/app';

const Title = ({ title }) => {
  const [current, setCurrent] = useState('mail');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const handleLogout = () => {
    dispatch(logout());
    dispatch(resetStore());
    navigate(LOGIN_SCREEN_PATH);
  };

  const onClick = (e) => {
    if (e.key === 'profile') {
      navigate(PROFILE_SCREEN_PATH);
    } else if (e.key === 'logout') {
      handleLogout();
    }
    setCurrent(e.key);
  };
  const items = [
    {
      key: 'SubMenu',
      icon: <SettingOutlined />,
      children: [
        {
          type: 'group',
          children: [
            {
              label: 'Perfil',
              key: 'profile',
            },
            {
              label: 'Cerrar sesión',
              key: 'logout',
            },
          ],
        },
      ],
    },
  ];

  return (
    <div className="container-header">
      <div className="container-header__title">
        <h1>{title}</h1>
      </div>
      <div>
        {!isMobile && <Menu onClick={onClick} className="menu-logout" selectedKeys={[current]} mode="horizontal" items={items} theme="light" />}
      </div>
    </div>
  );
};
Title.propTypes = {
  title: PropTypes.string.isRequired,

};

export default Title;
