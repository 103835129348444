import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { Select, Spin } from 'antd';
import Title from '../../components/title';
import Table from '../../components/table';
import { columnsHome } from '../../utils/parsers/tableParser';
import { fetchHome } from '../../utils/calls';
import ApexChart from '../../components/line-charts';
import { parseHomeEmployeeList } from '../../utils/parsers/parserHomeEmployee';
import { parseHomeProductList } from '../../utils/parsers/parserHomeProduct';
import { withAuth } from '../../hoc';

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState({});
  const [dataOrders, setDataOrders] = useState({});
  const [dataProducts, setDataProducts] = useState({});
  const [dataSupplier, setDataSupplier] = useState({});
  const [selectedPeriodOrders, setSelectedPeriodOrders] = useState('monthly');
  const [selectedPeriodProducts, setSelectedPeriodProducts] = useState('monthly');
  const [selectedPeriodSupplier, setSelectedPeriodSupplier] = useState('monthly');
  const [selectedPeriodTopEmployees, setSelectedPeriodTopEmployees] = useState('monthly');
  const [selectedPeriodTopProduct, setSelectedPeriodTopProduct] = useState('monthly');
  const [selectedPeriodGraph, setSelectedPeriodGraph] = useState('monthly');
  const [loadingPeriodOrders, setLoadingPeriodOrders] = useState(false);
  const [loadingPeriodProducts, setLoadingPeriodProducts] = useState(false);
  const [loadingPeriodSupplier, setLoadingPeriodSupplier] = useState(false);
  const [loadingPeriodTopEmployees, setLoadingPeriodTopEmployees] = useState(false);
  const [loadingPeriodTopProduct, setLoadingPeriodTopProduct] = useState(false);
  const [loadingPeriodGraph, setLoadingPeriodGraph] = useState(false);
  const [dataTopEmployees, setDataTopEmployees] = useState([]);
  const [dataTopProduct, setDataTopProduct] = useState([]);
  const [dataGraph, setDataGraph] = useState([]);

  const options = [
    {
      value: 'daily',
      label: 'Diario'
    },
    {
      value: 'monthly',
      label: 'Mensual'
    },
    {
      value: 'annual',
      label: 'Anual'
    }
  ];

  useEffect(() => {
    fetchHome('monthly')
      .then((response) => {
        setInitialData(response.data);
        setDataOrders(response.data);
        setDataProducts(response.data);
        setDataSupplier(response.data);
        setDataTopEmployees(response.data.dataEmployees);
        setDataTopProduct(response.data.dataProducts);
        setDataGraph(response.data.graphByMonth);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  }, []);

  const handlePeriodChange = (period, setPeriod, setLoading, setData) => {
    setPeriod(period);
    setLoading(true);
    setData(initialData);
    fetchHome(period)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const handlePeriodTopEmployeesChange = (period) => {
    setSelectedPeriodTopEmployees(period);
    setLoadingPeriodTopEmployees(true);
    fetchHome(period)
      .then((response) => {
        setDataTopEmployees(response.data.dataEmployees);
        setLoadingPeriodTopEmployees(false);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const handlePeriodTopProductChange = (period) => {
    setSelectedPeriodTopProduct(period);
    setLoadingPeriodTopProduct(true);
    fetchHome(period)
      .then((response) => {
        setDataTopProduct(response.data.dataProducts);
        setLoadingPeriodTopProduct(false);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const handlePeriodGraph = (period) => {
    setSelectedPeriodGraph(period);
    setLoadingPeriodGraph(true);
    fetchHome(period)
      .then((response) => {
        setDataGraph(response.data.graphByMonth);
        setLoadingPeriodGraph(false);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  if (Object.keys(initialData).length === 0) {
    return <Spin size="large" fullscreen />;
  }

  return (
    <>
      <div className="container-header">
        <Title title={t('title.home')} />
      </div>
      <div className="container-home">
        <div className={isMobile ? 'container-home-mobile' : 'container-home__info'}>
          <div className="container-home__count">
            <div className={isMobile && 'container-home-mobile-header'}>
              <h3>{t('home.countOrders')}</h3>
              <Select
                loading={loadingPeriodOrders}
                disabled={loadingPeriodOrders}
                className="select-home"
                options={options}
                value={selectedPeriodOrders}
                onChange={(value) => handlePeriodChange(
                  value,
                  setSelectedPeriodOrders,
                  setLoadingPeriodOrders,
                  setDataOrders
                )}
              />
            </div>
            <p>{dataOrders.ordersCount ? dataOrders?.ordersCount : '-'}</p>
          </div>
          <div className="container-home__count">
            <div className={isMobile && 'container-home-mobile-header'}>
              <h3>{t('home.productMostSale')}</h3>
              <Select
                loading={loadingPeriodProducts}
                disabled={loadingPeriodProducts}
                className="select-home"
                options={options}
                value={selectedPeriodProducts}
                onChange={(value) => handlePeriodChange(
                  value,
                  setSelectedPeriodProducts,
                  setLoadingPeriodProducts,
                  setDataProducts
                )}
              />
            </div>
            <p title={dataProducts?.dataProducts?.mostRepeatedProduct?.name || '-'}>{dataProducts?.dataProducts?.mostRepeatedProduct?.name ? dataProducts?.dataProducts?.mostRepeatedProduct?.name : '-'}</p>
          </div>
          <div className="container-home__count">
            <div className={isMobile && 'container-home-mobile-header'}>
              <h3>{t('home.supplierMostUsed')}</h3>
              <Select
                options={options}
                loading={loadingPeriodSupplier}
                disabled={loadingPeriodSupplier}
                className="select-home"
                value={selectedPeriodSupplier}
                onChange={(value) => handlePeriodChange(
                  value,
                  setSelectedPeriodSupplier,
                  setLoadingPeriodSupplier,
                  setDataSupplier
                )}
              />
            </div>
            <p title={dataSupplier?.dataSuppliers?.mostRepeatedSupplier?.name || '-'}>{dataSupplier?.dataSuppliers?.mostRepeatedSupplier?.name ? dataSupplier?.dataSuppliers?.mostRepeatedSupplier?.name : '-'}</p>
          </div>
        </div>
        <div className={isMobile ? 'table-container-mobile' : 'container-home__info-table'}>
          <div className={isMobile ? 'table-container-mobile' : 'table-container'}>
            <div className="table-select">
              <Select
                options={options}
                loading={loadingPeriodTopEmployees}
                disabled={loadingPeriodTopEmployees}
                className="select-home"
                value={selectedPeriodTopEmployees}
                onChange={(value) => {
                  handlePeriodTopEmployeesChange(value);
                }}
              />
              <h3>{t('home.topFiveSales')}</h3>
            </div>
            <Table
              columns={columnsHome}
              actions={null}
              dataSource={parseHomeEmployeeList(dataTopEmployees?.top5)}
              scroll={{ x: 500 }}
            />
          </div>
          <div className={isMobile ? 'table-container-mobile' : 'table-container'}>
            <div className="table-select">
              <Select
                options={options}
                loading={loadingPeriodTopProduct}
                disabled={loadingPeriodTopProduct}
                className="select-home"
                value={selectedPeriodTopProduct}
                onChange={(value) => handlePeriodTopProductChange(value)}
              />
              <h3>{t('home.topFiveClient')}</h3>
            </div>
            <Table
              columns={columnsHome}
              actions={null}
              dataSource={parseHomeProductList(dataTopProduct?.top5)}
              scroll={{ x: 500 }}
            />
          </div>
        </div>
        <div className="container-home__info-chart">
          <div className="table-select">
            <Select
              options={options}
              loading={loadingPeriodGraph}
              disabled={loadingPeriodGraph}
              className="select-home"
              value={selectedPeriodGraph}
              onChange={(value) => {
                handlePeriodGraph(value);
              }}
            />
            <h3>{t('home.countOrders')}</h3>
          </div>
          <ApexChart seriesData={dataGraph} selectedPeriodGraph={selectedPeriodGraph} />
        </div>
      </div>
    </>
  );
};

export default withAuth(Home);
