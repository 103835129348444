/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editeProduct,
  fetchAllTaxes,
  fetchAllTypesProducts,
  fetchCategories,
  fetchCategoriesByCompany,
  fetchCompanies,
  fetchOneProduct,
  fetchProfile,
  fetchSuppliers,
  fetchSuppliersByCompany,
  fetchTaxesByCompany
} from '../../../utils/calls';
import { PRODUCTS_SCREEN_PATH } from '../../../routes/paths';
import FormProducts from '../form';
import { rolKey } from '../../../utils/keys';
import { Roles } from '../../../utils/enums/roles';
import { withAuth } from '../../../hoc';

const EditProducts = ({ isEdit }) => {
  const rol = localStorage.getItem(rolKey);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoadingEditProduct, setisLoadingEditProduct] = useState(false);
  const [oneProduct, setOneProduct] = useState();
  const [productsTypes, setOneProductsTypes] = useState();
  const [companies, setCompanies] = useState();
  const [categories, setCategories] = useState();
  const [suppliers, setSuppliers] = useState();
  const [taxes, setTaxes] = useState();
  const [taxesDefault, setTaxesDefault] = useState();
  const [profile, setProfile] = useState();
  const [form, setForm] = useState({
    name: null,
    description: null,
    price: null,
    productType: null,
    supplier: null,
    category: null,
    company: null,
    taxes: null,
    stock: null

  });

  const getProductsTypes = () => {
    fetchAllTypesProducts()
      .then((response) => {
        setOneProductsTypes(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getProfile = () => {
    fetchProfile()
      .then((response) => {
        setProfile(response?.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getProductsTypes();
    if (rol === Roles.SUPERADMIN) {
      getCompanies();
    }
    if (rol === Roles.ADMINCOMPANY) {
      getProfile();
    }
  }, []);

  const redirect = () => {
    navigate(PRODUCTS_SCREEN_PATH);
  };

  const handleChange = (newForm) => {
    setForm(newForm);
  };

  const handleEdit = (formData) => {
    const formParser = {
      ...form,
      taxes: formData.taxes,
      deletedTaxes: formData.deletedTaxes,
    };
    setisLoadingEditProduct(true);
    editeProduct(id, formParser)
      .then(() => {
        toast.success(t('toastSuccess.editProduct'));
        redirect();
      })
      .catch(() => {
        toast.error(t('toastError.editProduct'));
        setisLoadingEditProduct(false);
      });
  };

  const getOneProduct = () => {
    if (isEdit) {
      fetchOneProduct(id)
        .then((response) => {
          setOneProduct(response.data);
          setTaxesDefault(response.data?.taxes
            ? response.data.taxes.map((taxe) => taxe?.tax?.id) : []);
        })
        .catch(() => {
          toast.error(t('toastError.fetchingError'));
        });
    }
  };

  const getCategories = () => {
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getSuppliers = () => {
    fetchSuppliers()
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getTaxes = () => {
    fetchAllTaxes()
      .then((response) => {
        setTaxes(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };
  useEffect(() => {
    getCategories();
    getSuppliers();
    getTaxes();
    getOneProduct();
  }, []);

  const getCategoriesByCompany = (idCompany) => {
    fetchCategoriesByCompany(idCompany)
      .then((response) => {
        setCategories(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getSuppliersByCompany = (idCompany) => {
    fetchSuppliersByCompany(idCompany)
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getTaxesByCompany = (idCompany) => {
    fetchTaxesByCompany(idCompany)
      .then((response) => {
        setTaxes(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    if (form.company) {
      getTaxesByCompany(form.company);
      getSuppliersByCompany(form.company);
      getCategoriesByCompany(form.company);
    }
  }, [form.company]);

  useEffect(() => {
    if (isEdit) {
      const dataEdit = {
        name: oneProduct ? oneProduct?.name : '',
        price: oneProduct ? oneProduct?.price : '',
        stock: oneProduct ? oneProduct?.stock : '',
        productType: oneProduct ? oneProduct?.productType?.id : '',
        description: oneProduct ? oneProduct?.description : '',
        supplier: oneProduct ? oneProduct?.supplier?.id : '',
        category: oneProduct ? oneProduct?.category?.id : '',
        company: oneProduct ? oneProduct?.company?.id : '',
        taxes: oneProduct?.taxes ? oneProduct.taxes.map((taxe) => taxe?.tax?.id) : [],
      };
      setForm(dataEdit);
    }
  }, [oneProduct]);

  return (
    <FormProducts
      data={form}
      isEdit={isEdit}
      onChange={handleChange}
      onEdit={handleEdit}
      oneProduct={oneProduct}
      productsTypes={productsTypes}
      companies={companies}
      categories={categories}
      suppliers={suppliers}
      taxes={taxes}
      taxesDefault={taxesDefault}
      profile={profile}
      isLoadingEditProduct={isLoadingEditProduct}
    />
  );
};

EditProducts.propTypes = {
  isEdit: PropTypes.bool,
};

EditProducts.defaultProps = {
  isEdit: true,
};

export default withAuth(EditProducts);
