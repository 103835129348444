/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Pagination, Space } from 'antd';
import { EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import HeaderFollowUp from '../header';
import { fetchAllClients, fetchAllEmployees, fetchAllTrackings, fetchCompanies } from '../../../utils/calls';
import Button from '../../../components/button';
import { FOLLOWUP_SCREEN_PATH } from '../../../routes/paths';
import HeaderSearch from '../../../components/header-search';
import TableComponent from '../../../components/table';
import { columnsTrackings } from '../../../utils/parsers/tableParser';
import { parseTrackingList } from '../../../utils/parsers/parserTracking';
import { searchTrackings, searchTrackingsCompanies } from '../../../utils/searchs';
import { typesTrackings } from '../../../utils/typesTracking';
import { withAuth } from '../../../hoc';
import { Roles } from '../../../utils/enums/roles';
import { rolKey } from '../../../utils/keys';

const BodyFollowUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState();
  const [inputs, setInputs] = useState([]);
  const [customers, setcustomers] = useState();
  const [companies, setCompanies] = useState(null);
  const pageSize = 10;
  const rol = localStorage.getItem(rolKey);
  const isRoleSuperAdmin = rol === Roles.SUPERADMIN;

  const getTrackings = () => {
    setLoading(true);
    setData([]);
    fetchAllTrackings(currentPage, pageSize, search)
      .then((response) => {
        setData(response.data);
        setTotalItems(response?.data?.totalItems);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getClients = () => {
    fetchAllClients()
      .then((response) => {
        setcustomers(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  const getEmployees = () => {
    fetchAllEmployees()
      .then((response) => {
        setEmployees(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };
  const getCompanies = () => {
    fetchCompanies()
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => {
        toast.error(t('toastError.fetchingError'));
      });
  };

  useEffect(() => {
    getTrackings();
    getClients();
    getEmployees();
    if (isRoleSuperAdmin) {
      getCompanies();
    }
  }, []);

  const redirect = (user) => {
    navigate(`${FOLLOWUP_SCREEN_PATH}/${user.key}`);
  };

  const handleActionsColumn = (tracking) => (
    (
      <Space size="middle">
        <Button
          id="editButton"
          text={<EyeFilled />}
          color="edit"
          onClick={() => {
            redirect(tracking);
          }}
        />
      </Space>
    )
  );

  const handlePageSearch = (text) => {
    setSearch(text);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (search) { getTrackings(); }
  }, [currentPage, search]);

  useEffect(() => {
    getTrackings();
  }, [currentPage]);

  useEffect(() => {
    if (employees && customers) {
      const inputSearch = isRoleSuperAdmin ? searchTrackingsCompanies(customers, employees, typesTrackings, companies) : searchTrackings(customers, employees, typesTrackings);
      setInputs(inputSearch);
    }
  }, [employees, customers, companies]);
  return (
    <>
      <HeaderFollowUp />
      <HeaderSearch inputs={inputs} onSearch={handlePageSearch} />

      <div className="body-container__table">
        <TableComponent
          columns={columnsTrackings}
          loading={loading}
          dataSource={parseTrackingList(data?.data)}
          actionsColumn={handleActionsColumn}
          scroll={{ x: 1200 }}
        />
      </div>
      <div className="body-container__pagination">
        <Pagination
          current={currentPage}
          total={totalItems}
          pageSize={pageSize}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default withAuth(BodyFollowUp);
